import CodeOutlinedIcon from "@material-ui/icons/CodeOutlined";
import { leoThemeConfig } from "leo-theme-config";
import { leoColorWhite } from "LEOTheme/LEOColors";
import { leoLayoutNavBarWidth } from "LEOTheme/LEOTheme";
import { aquireBeApiToken_Silent } from "LEOTheme/utils/auth-utils";
import { getENV } from "LEOTheme/utils/string-utils";
import { getTypeGenCommand } from "LEOTheme/utils/typegen";
import React from "react";

export interface LEOCopyTokenButtonProps {}

/**
 * Navbar item that copies type gen command to clipboard.
 *
 * @param props LEOCopyTokenButtonProps
 */
export const LEOCopyTokenButton = () => {
  /**
   * Local state
   */
  const env = getENV();
  /**
   * Render
   */
  return env === "local" ? (
    <div
      style={{
        width: leoLayoutNavBarWidth,
        height: leoLayoutNavBarWidth,
        color: leoColorWhite,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
      }}
      onClick={async () => {
        const token = await aquireBeApiToken_Silent();
        const input = document.body.appendChild(
          document.createElement("input")
        );
        input.value = getTypeGenCommand(
          process.env.REACT_APP_API_ENDPOINT,
          token.accessToken,
          leoThemeConfig.disableGQLFiles || [],
          leoThemeConfig.disableThemeGQLFiles || []
        );
        input.focus();
        input.select();
        document.execCommand("copy");
        input.parentNode.removeChild(input);
        console.log("Full npx script copied to clipboard");
      }}
    >
      <CodeOutlinedIcon fontSize={"large"} />
      <span style={{ fontSize: 10, fontWeight: 900, textAlign: "center" }}>
        {"TYPE GEN COMMAND"}
      </span>
    </div>
  ) : (
    <></>
  );
};
