import {
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  Typography,
  useTheme,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { leoSpacing } from "LEOTheme/LEOTheme";
import React, { CSSProperties } from "react";
import LEOSecondaryButton from "./LEOSecondaryButton";
import { LEOTooltipButton } from "./LEOTooltipButton";

interface LEOMultiSelectOptionProps {
  /** Key / value of item */
  key: any;

  /** Label */
  label: string;

  /** Group key if options should be grouped */
  group?: string;

  /** Tooltip  */
  tooltip?: React.ReactNode;
}

export interface LEOMultiSelectGroupProps {
  key: any;
  label: string;
  group?: string;
}

export interface LEOMultiSelectProps {
  /** Class to add to container */
  className?: string;

  /** Group items in columns */
  groups?: LEOMultiSelectGroupProps[];

  /** Lable for input */
  label?: string;

  /** Options */
  options: LEOMultiSelectOptionProps[];

  /** Selected options. Arrary of the item keys. */
  selected: any[];

  /** Selected options. Arrary of the item keys. */
  reset?: any[];

  /** Callback on change. Passes array of keys. */
  onChange: (selectedKeys: any[]) => void;

  /** Style */
  style?: CSSProperties;

  /** hide selection buttons */
  hideSelectionButtons?: boolean;
}

const LEOMultiSelect = (props: LEOMultiSelectProps) => {
  const { onChange, label, options, selected, className } = props;

  /**
   * Style
   */
  const theme = useTheme();

  /**
   * Local state
   */
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  /**
   * Utils
   */
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const groupSets = props.groups
    ? getGroupSets(props.groups, props.options)
    : undefined;

  const optionsFragment = (o: LEOMultiSelectProps["options"][0]) => (
    <FormControlLabel
      style={{
        display: "block",
        marginBottom: -10,
      }}
      key={o.key}
      value="start"
      control={
        <Checkbox
          onChange={(e) => {
            if (e.target.checked) {
              onChange([...selected, o.key]);
            } else {
              onChange(selected.filter((k) => k !== o.key));
            }
          }}
          checked={selected.includes(o.key)}
          color="primary"
        />
      }
      label={
        <>
          {o.label}
          {o.tooltip && <LEOTooltipButton onHover tooltip={o.tooltip} />}
        </>
      }
      labelPlacement="end"
    />
  );
  /**
   * React
   */
  return (
    <div
      style={{
        position: "relative",
        paddingTop: leoSpacing * 0.5,
        paddingBottom: leoSpacing * 0.5,
        marginTop: leoSpacing * 0.25,
        ...props.style,
      }}
      className={className}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
          cursor: "pointer",
          paddingTop: leoSpacing,
          paddingBottom: leoSpacing,
        }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
      >
        {label}
        <span
          style={{
            whiteSpace: "nowrap",
            fontSize: 11,
            fontWeight: 900,
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            marginLeft: leoSpacing * 1,
            marginRight: leoSpacing * 0.5,
            padding: leoSpacing * 0.25,
            paddingLeft: leoSpacing * 1,
            paddingRight: leoSpacing * 1,
            borderRadius: leoSpacing * 3,
          }}
        >
          {`${selected.length} / ${options.length}`}
        </span>
        <ArrowDropDownIcon fontSize={"small"} />
      </div>
      <Popover
        disableScrollLock
        id={id}
        onClose={() => setAnchorEl(null)}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <CardContent>
          {options.length > 4 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: theme.spacing(1),
                marginBottom: theme.spacing(1),
              }}
            >
              {!props.hideSelectionButtons && (
                <div>
                  <LEOSecondaryButton
                    size={"small"}
                    style={{ flex: 1 }}
                    onClick={() => onChange(options.map((o) => o.key))}
                  >
                    {"Select all"}
                  </LEOSecondaryButton>
                  <LEOSecondaryButton
                    size={"small"}
                    style={{ flex: 1 }}
                    onClick={() => onChange([])}
                  >
                    {"Deselect all"}
                  </LEOSecondaryButton>
                </div>
              )}
              <div>
                {props.reset && (
                  <LEOSecondaryButton
                    size={"small"}
                    style={{ flex: 1 }}
                    onClick={() =>
                      onChange(
                        options
                          .map((o) => o.key)
                          .filter((o) => !props.reset.includes(o))
                      )
                    }
                  >
                    {"Reset"}
                  </LEOSecondaryButton>
                )}
              </div>
            </div>
          )}
          {!props.groups && (
            <FormGroup>{options.map((o) => optionsFragment(o))}</FormGroup>
          )}
          {props.groups && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // whiteSpace: 'nowrap'
              }}
            >
              {groupSets.map((gs, i) => (
                <div key={i}>
                  {gs.map((g, i) => (
                    <div key={i}>
                      <Typography
                        style={{
                          marginTop: leoSpacing * 2,
                          marginBottom: -0.5 * leoSpacing,
                        }}
                        variant="h5"
                      >
                        {g.label}
                      </Typography>
                      {props.options
                        .filter((o) => o.group === g.key)
                        .map((o) => optionsFragment(o))}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </CardContent>
      </Popover>
    </div>
  );
};

const getGroupSets = (
  groups: LEOMultiSelectGroupProps[],
  options: LEOMultiSelectOptionProps[]
): LEOMultiSelectGroupProps[][] => {
  let optionCount = 0;
  const columns = [[]];
  let currentColumn = columns[0];
  groups.forEach((g) => {
    currentColumn.push(g);
    //
    optionCount += options.filter((o) => o.group === g.key).length;
    if (optionCount > 4) {
      optionCount = 0;
      currentColumn = [];
      columns.push(currentColumn);
    }
  });
  return columns;
};

export default LEOMultiSelect;
