import { Epic, fetchEpics } from "fetch-epics";
import { LEOAppFrame } from "LEOTheme/components/LEOAppFrame";
import LEOMultiSelect from "LEOTheme/components/LEOMultiSelect";
import LEOTabsNav from "LEOTheme/components/LEOTabsNav";
import { leoSpacing } from "LEOTheme/LEOTheme";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import { useLocalStorage } from "LEOTheme/utils/use-local-storage";
import React, { useEffect, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Analytics } from "./Analytics";
import { ProjectDialog } from "./ProjectDialog";
import { ProjectOverview } from "./ProjectOverview";
import { StatusOverview } from "./StatusOverview";

interface MainProps {
  appMetadata: AppMetadata;
}

/**
 *
 *
 * @param props MainProps
 */
export const Main = (props: MainProps) => {
  /**
   * Local state
   */

  const [tabIndex, setTabIndex] = useLocalStorage(
    "dashboard-main-tab-index",
    0
  );

  const [epics, setEpics] = useState<Epic[] | null>([]);
  const [hidden, setHidden] = useLocalStorage<string[] | null>(
    "hidden-projects-1",
    []
  );

  /**
   * Effects and utils
   */

  useEffect(() => {
    (async () => {
      const epics = await fetchEpics();

      // set local
      setEpics(epics);
    })();
  }, []);

  /**
   * Render
   */
  return (
    <LEOAppFrame
      LEOAppBarProps={{
        appMetadata: props.appMetadata,
        leftOfAvatar: (
          <>
            <LEOMultiSelect
              style={{
                marginRight: leoSpacing * 2,
                position: "relative",
                top: -1,
              }}
              label={"Projects"}
              selected={(epics || [])
                .filter((p) => !hidden.includes(p.title))
                .map((p) => p.title)}
              onChange={(v) =>
                setHidden(
                  epics.filter((p) => !v.includes(p.title)).map((p) => p.title)
                )
              }
              options={
                epics?.map((p) => ({
                  key: p.title,
                  label: p.title,
                })) || []
              }
            />
          </>
        ),
      }}
      appMetadata={props.appMetadata}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        <Switch>
          <Route path={["/:projectSlug", "/"]}>
            <LEOTabsNav
              style={{
                marginTop: leoSpacing * 4,
                flex: 1,
              }}
              selectedIndex={tabIndex}
              onSelectedChange={(i) => setTabIndex(i)}
              tabs={[
                {
                  label: "Project overview",
                  renderContent: () => (
                    <ProjectOverview hiddenProjects={hidden} epics={epics} />
                  ),
                },
                {
                  label: "Status overview",
                  renderContent: () => <StatusOverview epics={epics} />,
                },
                {
                  label: "Analytics",
                  renderContent: () => <Analytics />,
                },
              ]}
            />
            <DialogWrapper projects={epics} />
          </Route>
        </Switch>
      </div>
    </LEOAppFrame>
  );
};

interface DialogWrapperProps {
  projects: Epic[];
}

const DialogWrapper = (props: DialogWrapperProps) => {
  const match = useRouteMatch();
  const epicSlug = match.params["projectSlug"];
  const epic = props.projects.find((e) => e.slug === epicSlug);
  if (epic) {
    return <ProjectDialog epic={epic} />;
  } else {
    return null;
  }
};
