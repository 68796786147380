import { IconButton, Tooltip } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { CSSProperties } from "@material-ui/styles";
import { leoSpacing } from "LEOTheme/LEOTheme";
import { useEventListener } from "LEOTheme/utils/use-event-listener";
import React, { useRef, useState } from "react";

export interface LEOTooltipButtonProps {
  //
  tooltip: React.ReactNode;
  onHover?: boolean;

  // style
  style?: CSSProperties;
}

/**
 * Click button to display tooltip. Click outside to close tooltip.
 *
 * @param props LEOTooltipButtonProps
 */
export const LEOTooltipButton = (props: LEOTooltipButtonProps) => {
  /**
   * Local state
   */
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useEventListener("click", (e) => {
    if (!buttonRef.current.contains(e.target)) {
      setTooltipOpen(false);
    }
  });
  /**
   * Render
   */
  return (
    <Tooltip
      title={<span style={{ whiteSpace: "pre" }}>{props.tooltip}</span>}
      arrow
      PopperProps={!props.onHover ? { open: tooltipOpen } : {}}
      placement="top"
    >
      <IconButton
        style={{
          marginLeft: leoSpacing * 0.5,
          ...props.style,
        }}
        size={"small"}
        ref={buttonRef}
        onClick={(e) => {
          e.preventDefault();
          // e.stopPropagation();
          setTooltipOpen(true);
        }}
      >
        <HelpIcon style={{ fontSize: 15, color: "rgba(0, 0, 0, 0.25)" }} />
      </IconButton>
    </Tooltip>
  );
};
