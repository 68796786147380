import {
  Button,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import { leoColorPetrol0, leoColorWhite } from "LEOTheme/LEOColors";
import { leoLayoutAppBarHeight } from "LEOTheme/LEOTheme";
import React from "react";

const useStyles = makeStyles(() => ({
  header: {
    color: (props: any) => (props.darkHeader ? leoColorWhite : undefined),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& h2": {
      flex: 1,
      display: "flex",
      justifyContent: "space-between",
    },
  },
}));

export interface LEODialogProps extends DialogProps {
  /** Title of dialog */
  title: string;

  /** If header shold have dark blue background */
  darkHeader?: boolean;

  /** Footer element. Used for action buttons like cancel and submit */
  footer?: React.ReactNode;

  /** Top bar actions element. Will add content to the right of the title  */
  topBarActions?: React.ReactNode;
}

/**
 * Wrapper component for MUI Dialog
 *
 * https://material-ui.com/components/dialogs/
 *
 * @param props LEODialogProps
 */
const LEODialog = (props: LEODialogProps) => {
  /*
   * Style
   */
  const { header } = useStyles(props);
  const theme = useTheme();

  /**
   * Utils
   */
  const { title, onClose } = props;
  const cleanProps = { ...props };
  delete cleanProps["darkHeader"];
  delete cleanProps["title"];
  delete cleanProps["topBarActions"];

  /**
   * Render
   */
  return (
    <Dialog
      disableScrollLock
      {...cleanProps}
      PaperProps={{
        style: {
          backgroundColor: props.darkHeader ? leoColorPetrol0 : undefined,
          ...props.style,
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: leoLayoutAppBarHeight,
          backgroundColor: props.darkHeader ? leoColorPetrol0 : leoColorWhite,
        }}
      >
        <DialogTitle
          style={{
            backgroundColor: props.darkHeader ? leoColorPetrol0 : undefined,
          }}
          disableTypography
          className={header}
        >
          <Typography
            style={{
              margin: 0,
            }}
            variant="h2"
          >
            {title}
          </Typography>
        </DialogTitle>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginRight: theme.spacing(3),
          }}
        >
          {props.topBarActions}
          <Button
            style={{
              color: props.darkHeader ? "white" : undefined,
            }}
            onClick={(e) => {
              if (onClose) {
                onClose(e, "backdropClick");
              }
            }}
            startIcon={<Close />}
          >
            {"Close"}
          </Button>
        </div>
      </div>
      {props.children}
      {props.footer && (
        <DialogContent
          style={{
            paddingTop: theme.spacing(2),
            flexDirection: "row",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            backgroundColor: props.darkHeader ? leoColorWhite : "transparent",
          }}
        >
          {props.footer}
        </DialogContent>
      )}
    </Dialog>
  );
};

export default LEODialog;
