import { Card, Grid, makeStyles, Typography } from "@material-ui/core";
import { Epic } from "fetch-epics";
import { leoColorLightGreen5, leoColorSand2 } from "LEOTheme/LEOColors";
import {
  leoBorderRadiusMain,
  leoSpacing,
  leoStyleFlexRowCenter,
} from "LEOTheme/LEOTheme";
import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { EnvStatusTag } from "./EnvStatusTag";

interface ProjectCardProps {
  epic: Epic;
}

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: leoColorSand2,
    cursor: "pointer",
    transition: "background-color .15s ease-in-out",
    "& .dev-ops-container": {
      transition: "opacity .35s ease-in-out",
      opacity: 0,
    },
    "& .arrow-icon": {
      transition: "opacity .35s ease-in-out",
      opacity: 0,
    },
    "&:hover .arrow-icon,&.active .arrow-icon": {
      opacity: 1,
    },
    "&:hover,&.active": {
      backgroundColor: leoColorLightGreen5,
      "& .dev-ops-container": {
        opacity: 1,
      },
    },
    "& small": {
      opacity: 0.75,
    },
    "&:hover > small": {
      opacity: 1,
    },
  },
  devOpsLink: {
    transition: "opacity .15s ease-in-out",
    "&:hover": {
      opacity: 0.65,
    },
  },
}));

/**
 * Project card
 *
 * @param props ProjectCardProps
 */
export const ProjectCard = (props: ProjectCardProps) => {
  const classes = useStyles(props);
  const history = useHistory();

  /**
   * states
   */

  const root = useRef<HTMLDivElement>();

  /**
   * Render
   */
  return (
    <Grid item ref={root} xs={12} sm={6} md={4} lg={3}>
      <Card
        className={classes.root}
        onClick={() => history.push("/" + props.epic.slug)}
        style={{
          ...leoStyleFlexRowCenter,
        }}
      >
        <div
          style={{
            width: 80,
            height: 80,
            flexShrink: 0,
            borderTopLeftRadius: leoBorderRadiusMain * 1.5,
            borderBottomLeftRadius: leoBorderRadiusMain * 1.5,
            backgroundImage: `url(/app-icons/${props.epic.id}.png)`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <div
          style={{
            position: "relative",
            padding: leoSpacing * 2,
          }}
        >
          <div
            style={{
              ...leoStyleFlexRowCenter,
              alignItems: "center",
              position: "relative",
            }}
          >
            <div
              style={{
                marginLeft: leoSpacing * 3,
                marginTop: -leoSpacing * 1,
                flex: 1,
              }}
            >
              <div
                style={{
                  ...leoStyleFlexRowCenter,
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      margin: 0,
                      marginBottom: leoSpacing,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    variant={"h3"}
                  >
                    {props.epic.title}
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  position: "relative",
                  top: leoSpacing * 0.5,
                  ...leoStyleFlexRowCenter,
                }}
              >
                {props.epic.application &&
                  props.epic.application.envs.map((e, i) => (
                    <EnvStatusTag
                      style={{
                        marginRight: leoSpacing,
                      }}
                      key={i}
                      env={e}
                    />
                  ))}
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Grid>
  );
};
