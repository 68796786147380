import { IconButton, ListItem, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import { NavigationItem } from "LEOTheme/interfaces/navigation";
import {
  leoColorLightGreen4,
  leoColorSand1,
  leoColorWhite,
} from "LEOTheme/LEOColors";
import {
  leoGetBorderString,
  leoLayoutAppBarHeight,
  leoSpacing,
} from "LEOTheme/LEOTheme";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import React, { useState } from "react";
import { useLocation } from "react-router";
import { LEOAccountAvatar } from "./LEOAccountAvatar";
import { LEONavLink } from "./LEONavLink";

interface LEOMobileNavProps {
  /** Itesm to render in menu */
  navigationItems: NavigationItem[];

  /** Props for app ident and about screen */
  appMetadata: AppMetadata;
}

const useStyles = makeStyles(() => ({
  navItem: {},
  navItemActive: {
    backgroundColor: leoColorLightGreen4,
  },
  navSubItemActive: {
    backgroundColor: leoColorLightGreen4,
  },
}));

/**
 * Mobile navigation
 *
 * @param props LEOMobileNavProps
 */
export const LEOMobileNav = (props: LEOMobileNavProps) => {
  /**
   * Local state
   */

  const [menuOpen, setMenuOpen] = useState(false);

  /**
   * Render
   */
  return (
    <>
      <IconButton
        style={{ marginRight: -1.85 * leoSpacing }}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <MenuIcon></MenuIcon>
      </IconButton>
      {menuOpen && (
        <div
          onClick={() => setMenuOpen(false)}
          style={{
            position: "fixed",
            top: leoLayoutAppBarHeight,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: leoColorWhite,
          }}
        >
          {props.navigationItems?.map((item, index) => (
            <LEOMobileNavItem key={index} {...item} />
          ))}
          <div
            style={{
              textAlign: "center",
              marginTop: leoSpacing * 5,
            }}
          >
            <LEOAccountAvatar appMetadata={props.appMetadata} />
          </div>
        </div>
      )}
    </>
  );
};

const LEOMobileNavItem = (props: NavigationItem) => {
  const passedProps = { ...props };
  delete passedProps["subNavigation"];

  const classes = useStyles();
  const location = useLocation();
  const hasSubNav = props.subNavigation?.length > 0;

  /**
   * Local state
   */

  // sub nav open if current subpage is in menu
  const [subNavOpen, setSubNavOpen] = useState(
    hasSubNav
      ? !!props.subNavigation.find(
          (i) => (i.to as string) === location.pathname
        )
      : false
  );

  /**
   * Render
   */

  return (
    <>
      <LEONavLink
        {...passedProps}
        exact
        activeClassName={classes.navItemActive}
        style={{
          display: "block",
          borderBottom: leoGetBorderString(),
        }}
      >
        <ListItem button>
          <span
            style={{
              flex: 1,
              fontSize: "1.2em",
            }}
          >
            {props.label}
          </span>
          <IconButton
            style={{
              ...(hasSubNav
                ? {}
                : {
                    opacity: 0,
                    pointerEvents: "none",
                  }),
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setSubNavOpen(!subNavOpen);
            }}
          >
            <ExpandMoreIcon></ExpandMoreIcon>
          </IconButton>
        </ListItem>
      </LEONavLink>
      {subNavOpen && (
        <div
          style={{
            backgroundColor: leoColorSand1,
          }}
        >
          {props.subNavigation.map((subItem, index) => (
            <LEONavLink
              activeClassName={classes.navSubItemActive}
              style={{
                display: "block",
                boxShadow:
                  index === 0
                    ? "inset 0px 15px 15px 0px rgba(0,0,0,0.07)"
                    : undefined,
              }}
              key={index}
              {...subItem}
            >
              <ListItem
                button
                style={{
                  backgroundColor: "transparent",
                  paddingTop: leoSpacing * 3,
                  paddingBottom: leoSpacing * 3,
                }}
              >
                {subItem.label}
              </ListItem>
            </LEONavLink>
          ))}
        </div>
      )}
    </>
  );
};
