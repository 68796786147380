import {
  createStyles,
  InputLabel,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { leoGetBorderString } from "LEOTheme/LEOTheme";
import React from "react";

const useStyles = makeStyles(() => {
  return createStyles({
    valueContainer: (props: LEOFieldValueProps) => {
      return {
        minHeight: 18,
        fontSize: 13,
        marginTop: ".25em",
        whiteSpace: "pre-wrap",
        ...(!props.multiline && props.limitText
          ? {
              textOverflow: "ellipsis",
              whiteSpace: "nowrap" as const,
              overflow: "hidden",
            }
          : {}),
      };
    },
  });
});

export interface LEOFieldValueProps {
  /** Field label */
  label: string | React.ReactNode;

  /** Value / children to render */
  children: React.ReactNode | string | any;

  /** Allow multiline */
  multiline?: boolean;

  /** Limit text */
  limitText?: boolean;

  /** underline */
  underline?: boolean;

  /** help text */
  helpText?: React.ReactNode | string;

  /** style */
  style?: CSSProperties;
}

/**
 * Field value used internally by input fields. The value field will be rendred when an field is set to !editable
 *
 * @param props LEOFieldValueProps
 */

const LEOFieldValue = (props: LEOFieldValueProps) => {
  /**
   * Local state
   */
  const { valueContainer } = useStyles(props);

  /**
   * Render
   */
  return (
    <div
      style={{
        borderBottom: props.underline ? leoGetBorderString() : undefined,
        opacity:
          props?.children === "" ||
          props?.children === undefined ||
          props?.children === null
            ? 0.5
            : 1,
        ...props.style,
      }}
    >
      {props.helpText ? (
        <Tooltip placement="top" arrow title={props.helpText}>
          <div
            style={{
              display: "inline-block",
            }}
          >
            <InputLabel shrink>
              {props.label}
              <InfoOutlinedIcon
                fontSize="small"
                style={{ position: "relative", top: 3, left: 3 }}
              />
            </InputLabel>
          </div>
        </Tooltip>
      ) : (
        <InputLabel shrink>{props.label}</InputLabel>
      )}

      <div className={valueContainer}>{props.children}</div>
    </div>
  );
};

export default LEOFieldValue;
