import { Card, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  NavigationItem,
  NavigationSubItem,
} from "LEOTheme/interfaces/navigation";
import {
  leoColorDarkGreen0,
  leoColorDarkGreen1,
  leoColorLightGreen4,
  leoColorOrange,
  leoColorWhite,
} from "LEOTheme/LEOColors";
import {
  leoLayoutAppBarHeight,
  leoLayoutNavBarWidth,
  leoSpacing,
} from "LEOTheme/LEOTheme";
import React, { useState } from "react";
import { NavLink, useRouteMatch } from "react-router-dom";
import { LEOCopyTokenButton } from "./LEOCopyTokenButton";
import { LEONavLink } from "./LEONavLink";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer,
    backgroundColor: leoColorDarkGreen0,
    position: "fixed",
    paddingTop: leoLayoutAppBarHeight,
    top: 0,
    bottom: 0,
    width: leoLayoutNavBarWidth,
  },
  subItem: {
    display: "block",
  },
  subItemActive: {
    display: "block",
    backgroundColor: leoColorLightGreen4,
    "&:hover .MuiListItem-root": { backgroundColor: "transparent" },
    "&:hover": {
      backgroundColor: leoColorLightGreen4,
    },
  },
  item: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: leoLayoutNavBarWidth,
    textAlign: "center",
    textDecoration: "none",
    color: leoColorWhite,
    transition: theme.transitions.create(["background-color"], {
      duration: 150,
    }),

    "&:hover,&.sub-nav-open": {
      color: leoColorWhite,
      backgroundColor: leoColorDarkGreen1,
    },
    "&:active": {
      color: leoColorWhite,
      backgroundColor: leoColorDarkGreen1,
    },
    "& img": {
      display: "block",
    },
    "& span": {
      marginTop: leoSpacing,
      color: "white !important",
      fontWeight: 900,
      fontSize: 10,
      textTransform: "uppercase",
    },
  },
  itemActive: {
    backgroundColor: leoColorOrange,

    "&:hover,&.sub-nav-open": {
      backgroundColor: leoColorOrange,
    },
  },
}));

export interface LEONavBarProps {
  /** Items for nav bar */
  navigationItems: NavigationItem[];
}

/**
 * Main nav bar to the right
 *
 * @param props LEONavBarProps
 */
const LEONavBar = (props: LEONavBarProps) => {
  /**
   * Style
   */
  const { root } = useStyles();

  /**
   * Render
   */
  return (
    <div className={root}>
      {props.navigationItems.map((i, index) => (
        <LEONavBarItem key={index} {...i} />
      ))}
      <LEOCopyTokenButton />
    </div>
  );
};

/**
 * Item used in nav bar
 *
 * @param props LEONavBarItemProps
 */
export const LEONavBarItem = (props: NavigationItem) => {
  const { item, itemActive } = useStyles();
  const passedProps = { ...props };
  delete passedProps["subNavigation"];
  const routeMatch = useRouteMatch();

  /**
   * local state
   */

  const [subNavOpen, setSubNavOpen] = useState(false);

  /**
   * Render
   */
  return (
    <div
      onMouseOver={() => setSubNavOpen(true)}
      onMouseLeave={() => setSubNavOpen(false)}
      style={{
        position: "relative",
        pointerEvents: props.disabled ? "none" : "unset",
      }}
    >
      <NavLink
        {...passedProps}
        exact={props.to === "/" || props.exact}
        activeClassName={itemActive}
        className={
          props.isActive && props.isActive(routeMatch)
            ? itemActive + " " + item
            : item + (subNavOpen ? " sub-nav-open" : "")
        }
      >
        <span
          style={{
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: props.disabled ? 0.5 : 1,
          }}
        >
          {props.src && <img src={props.src} alt="" />}
          {props.icon}
          <span>{props.label}</span>
        </span>
      </NavLink>
      {!!props.subNavigation && subNavOpen && (
        <LEONavBarSubMenu subNavigationItems={props.subNavigation} />
      )}
    </div>
  );
};

interface LEONavBarSubMenuProps {
  subNavigationItems: NavigationSubItem[];
}

/**
 * Sub menu for items with children
 *
 * @param props LEONavBarSubMenuProps
 */
const LEONavBarSubMenu = (props: LEONavBarSubMenuProps) => {
  const { subItem, subItemActive } = useStyles();

  /**
   * Render
   */
  return (
    <Card
      style={{
        position: "absolute",
        top: -leoSpacing,
        left: "95%",
        zIndex: 1500,
      }}
    >
      <div
        style={{
          position: "relative",
          backgroundColor: leoColorWhite,
          minWidth: leoSpacing * 15,
        }}
      >
        {props.subNavigationItems.map((item, index) => {
          return (
            <LEONavLink
              activeClassName={subItemActive}
              className={subItem}
              key={index}
              to={item.to}
            >
              <ListItem style={{ whiteSpace: "nowrap" }} button>
                {item.label}
              </ListItem>
            </LEONavLink>
          );
        })}
      </div>
    </Card>
  );
};

export default LEONavBar;
