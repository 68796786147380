import { CSSProperties } from "@material-ui/core/styles/withStyles";
import DoneIcon from "@material-ui/icons/Done";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { leoColorError0, leoColorLightGreen0 } from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import { useIsMounted } from "LEOTheme/utils/use-is-mounted";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import LEOSpinner, { LEOSpinnerProps } from "./LEOSpinner";

export type LEOFeedbackSpinnerState = "idle" | "spinning" | "success" | "error";
interface LEOFeedbackSpinnerProps {
  LEOSpinnerProps?: LEOSpinnerProps;

  /** style */
  style?: CSSProperties;

  /** State of feedback */
  state: LEOFeedbackSpinnerState;
}

/**
 * Spinenr f
 *
 * @param props LEOFeedbackSpinnerProps
 */
export const LEOFeedbackSpinner = (props: LEOFeedbackSpinnerProps) => {
  /**
   * Local state
   */

  const [spinnerState, setSpinnerState] = useState(props.state);
  const [initiated, setInitiated] = useState(false);

  const mounted = useIsMounted();

  // saved timeout
  const timeout = useRef(null);

  /**
   * Effects and utils
   */

  useEffect(() => {
    if (!mounted) return;
    setSpinnerState(props.state);
    switch (props.state) {
      case "spinning":
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        setInitiated(true);
        break;
      case "success":
        if (mounted) {
          timeout.current = _.delay(() => setSpinnerState("idle"), 1500);
        }
        break;
      default:
        break;
    }

    return () => {
      clearTimeout(timeout.current);
    };
    // eslint-disable-next-line
  }, [props.state]);

  /**
   * Render
   */
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...props.style,
      }}
    >
      {(() => {
        switch (spinnerState) {
          case "spinning":
            return <LEOSpinner {...props.LEOSpinnerProps}></LEOSpinner>;
          case "success":
            if (!initiated) return null;
            return (
              <DoneIcon
                style={{
                  marginTop: -leoSpacing,
                  marginBottom: -leoSpacing,
                  color: leoColorLightGreen0,
                }}
              />
            );
          case "error":
            return (
              <ErrorOutlineIcon
                style={{
                  color: leoColorError0,
                }}
              />
            );

          default:
            return null;
        }
      })()}
    </div>
  );
};

/**
 * Wrapper for feedback spinner that takes a mutation status object and handles display logic on its behalf
 */

interface LEOFeedbackSpinnerMutation {
  /** spinner props */
  LEOSpinnerProps?: LEOSpinnerProps;

  /** style */
  style?: CSSProperties;

  /** mutation */
  mutation: {
    loading: boolean;
    error?: any;
    called: boolean;
  };
}

export const LEOFeedbackSpinnerMutationWrapper = ({
  mutation,
  LEOSpinnerProps,
  style,
}: LEOFeedbackSpinnerMutation) => {
  let state: LEOFeedbackSpinnerProps["state"] = "idle";

  if (mutation.loading) {
    state = "spinning";
  } else {
    if (mutation.error) {
      state = "error";
    }
    if (mutation.called) {
      state = "success";
    }
  }

  /**
   * render
   */
  return (
    <LEOFeedbackSpinner
      style={style}
      LEOSpinnerProps={LEOSpinnerProps}
      state={state}
    />
  );
};
