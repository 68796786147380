import {
  Card,
  CardContent,
  CheckboxProps,
  makeStyles,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import {
  leoBorderColor,
  leoColorLightGreen5,
  leoColorPetrol4,
  leoColorPetrol5,
  leoColorWhite,
} from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import { asDict } from "LEOTheme/utils/type-utils";
import { useEventListener } from "LEOTheme/utils/use-event-listener";
import { useLocalStorage } from "LEOTheme/utils/use-local-storage";
import _ from "lodash";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { LEOCheckbox } from "./LEOCheckBox";
import { LEOInfiniteScrollSpinner } from "./LEOInfiniteScrollSpinner";
import LEOSecondaryButton from "./LEOSecondaryButton";
import { LEOTableDraggableHead } from "./LEOTableDraggableHead";

export const parseSort = (sort: string): LEOTableSort => {
  if (!sort) return null;
  if (sort.charAt(0) === "-") {
    return {
      column: sort.substr(1),
      direction: "ASC",
    };
  } else {
    return {
      column: sort,
      direction: "DESC",
    };
  }
};

const useStyles = makeStyles(() => ({
  columnHeader: {
    backgroundColor: (props: LEOTableProps<any, any, any>) =>
      props.stickyHeader && leoColorWhite,
    "& .leo-table-drag-handle": {
      opacity: 0,
    },
    "&:hover": {
      backgroundColor: leoColorLightGreen5,
    },
    "&:hover .leo-table-drag-handle": {
      opacity: 1,
    },
  },
  paddedSideCells: {
    "& td:first-child, & th:first-child": {
      paddingLeft: leoSpacing * 4,
    },
    "& td:last-child, & th:last-child": {
      paddingRight: leoSpacing * 4,
    },
  },
  activeRow: {
    "& td": {
      backgroundColor: leoColorPetrol4,
    },
  },
  contain: {
    wordBreak: "normal",
    "& td, & th, & th > div > span:first-child": {
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
}));

export interface LEOTableSort {
  /** Column to sort (column name) */
  column: string;

  /** Direction to sort */
  direction: "DESC" | "ASC";
}

export interface IRowKey {
  id: string;
}

export interface LEOTableColumn<Row, Name, Group> {
  /** Colum name (key) */
  name: Name;

  /** Style of cells in column */
  cellStyle?: CSSProperties;

  /** Style of cells in column */
  headerStyle?: CSSProperties;

  /** Column label used in header */
  label: string;

  /** Column label used in header */
  group?: Group;

  /** Hide column */
  hidden?: boolean;

  /** Enable sorting of column  */
  sortable?: boolean;

  /** Function to return value to sort by for given row */
  sortBy?: (row: Row) => any;

  /** Custom sort key to use instead of the name prop */
  sortKey?: string;

  /** Description  */
  columnHelpText?: string | React.ReactNode;

  /** Function to return Cell element   */
  render?: (
    row: Row,
    column?: LEOTableColumn<Row, Name, Group>
  ) => React.ReactNode;

  /** icon button left of header label */
  renderHeaderIcon?: (
    column: LEOTableColumn<Row, Name, Group>
  ) => React.ReactNode;

  /** Render tooltip */
  renderTooltip?: (
    row: Row,
    column: LEOTableColumn<Row, Name, Group>,
    content: React.ReactElement
  ) => React.ReactNode;

  /** Function to return Header element */
  renderHeader?: (column: LEOTableColumn<Row, Name, Group>) => React.ReactNode;

  /** sticky */
  sticky?: boolean;
}

export type LEOTableProps<Row extends { id: string }, Name, Group> = {
  /** loading */
  showLoading?: boolean;

  /** key prefix for local storage */
  uiStateKey?: string;

  /** No items label */
  noItemsLabel?: string;

  /** update trigger */
  updateTrigger?: string;

  /** Columns rezie enabled */
  columnsResizable?: boolean;

  /** no scroll hint */
  noScrollHint?: boolean;

  /** Contain flag - makes sure table stays within bounds by using elipses on individual cells */
  contain?: boolean;

  /** Apply padding to first and last cell */
  padSideCells?: boolean;

  /** Selected row keys */
  selected?: string[];

  /** Disable select all*/
  disableSelectAll?: boolean;

  /** Active row keys */
  active?: string;

  /** Hidden columns */
  hideColumns?: string[];

  /** Columns of tabel */
  columns: LEOTableColumn<Row, Name, Group>[];

  /** Selectable */
  rowSelectable?: (row: Row) => boolean;

  /** Table data */
  data: Row[];

  /** Style will be applied to table element */
  style?: CSSProperties;

  /** The current sort setting column + direction */
  sort?: string;

  /** Callback when column clicked for sort change */
  onSortChange?: (sort: string) => void;

  /** Columns order change callback */
  onColumnsOrderChange?: (order: Name[]) => void;

  /** Columns order */
  columnsOrder?: Name[];

  /** Callback when selected changes */
  onSelectionChange?: (selection: string[]) => void;

  /** Get custom styling for row */
  getRowStyle?: (row: Row) => CSSProperties;

  /** Render cell */
  onRowClicked?: (row: Row) => void;

  /** Render cell */
  rowDisabled?: (row: Row) => boolean;

  /** Render cell */
  renderCell?: (row: Row, columnName: string) => React.ReactNode;

  /** render filter dropdown */
  renderHeaderFilter?: (c: LEOTableColumn<Row, Name, Group>) => JSX.Element;

  /** sticky header */
  stickyHeader?: boolean;

  /** offset for sticky header */
  stickyHeaderOffset?: number;
};

export const LEOTable = <Row extends { id: string }>(
  props: LEOTableProps<Row, any, any>
) => {
  const classes = useStyles(props);

  /**
   * Check props
   */

  if (props.columnsResizable && !props.uiStateKey) {
    throw new Error(
      "LEOTable setting columnResizable requires a uiStateKey prop to be defined."
    );
  }

  /**
   * Local state
   */

  const tableRef = useRef(null);
  const containerRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);

  /**
   * Filtered columns
   */

  const filteredCoumns = _.sortBy(
    props.columns.filter(
      (c) => !(props.hideColumns || []).includes(c.name) && !c.hidden
    ),
    (c) => {
      if (props.columnsOrder) {
        return props.columnsOrder.indexOf(c.name);
      } else {
        return 0;
      }
    }
  );

  // final columns
  const finalColumns = [
    ...(props.onSelectionChange && props.selected
      ? [
          getSelectionColumn({
            tableProps: props,
            sortedData: props.data,
          }),
        ]
      : []),
    ...filteredCoumns,
  ];

  /**
   * effects and utils
   */

  useEventListener("resize", () => {
    if (props.columnsResizable) {
      setTableWidth(containerRef.current.getBoundingClientRect().width);
    }
  });

  useLayoutEffect(
    () => {
      if (props.columnsResizable) {
        setTableWidth(containerRef.current.getBoundingClientRect().width);
      }
    },
    // eslint-disable-next-line
    [containerRef, props.columnsResizable]
  );

  /**
   * Sort
   */
  const sort = parseSort(props.sort);

  return (
    <div className={"table-container"} ref={containerRef} style={{}}>
      <Table
        className={classes.contain}
        ref={tableRef}
        style={{
          ...(props.columnsResizable && {
            tableLayout: "fixed",
            minWidth: tableWidth,
            width: null,
          }),
          paddingTop: leoSpacing * 2,
          ...(props.stickyHeader && !props.columnsResizable
            ? {
                borderCollapse: "separate",
              }
            : {}),
          ...props.style,
        }}
      >
        <LEOTableDraggableHead
          renderHeaderFilter={props.renderHeaderFilter}
          uiStateKey={props.uiStateKey}
          stickyHeader={props.stickyHeader}
          stickyHeaderOffset={props.stickyHeaderOffset}
          columnsResizable={props.columnsResizable}
          classes={classes}
          filteredColumns={filteredCoumns}
          columns={finalColumns}
          onSortChange={props.onSortChange}
          columnsOrder={props.columnsOrder}
          onColumnsOrderChange={props.onColumnsOrderChange}
          tableRef={tableRef.current}
          padSideCells={props.padSideCells}
          sort={sort}
        />
        <Suspense fallback={() => <span>{"Loading"}</span>}>
          <TableBody>
            {(props.data || []).map((r) => {
              const rowIsActive =
                props.onRowClicked &&
                (!props.rowDisabled ||
                  (props.rowDisabled && !props.rowDisabled(r)));

              return (
                <TableRow
                  className={clsx([
                    props.padSideCells && classes.paddedSideCells,
                    r.id === props.active && classes.activeRow,
                  ])}
                  style={{
                    ...(props.getRowStyle ? props.getRowStyle(r) : null),
                  }}
                  hover={!!rowIsActive}
                  onClick={(e) => {
                    if (
                      document.getSelection &&
                      document.getSelection().type === "Range"
                    ) {
                      e.preventDefault();
                      return;
                    }
                    if (rowIsActive) {
                      props.onRowClicked(r);
                    }
                  }}
                  key={r.id}
                >
                  {finalColumns.map((c) => {
                    const content = (() => {
                      try {
                        // check for global render override
                        if (props.renderCell) {
                          const customCell = props.renderCell(r, c.name);
                          if (customCell) {
                            return customCell;
                          }
                        }

                        // custom column render overrride
                        if (c.render) {
                          return c.render(r, c);
                        }

                        // default render
                        if (typeof asDict(r)[c.name] === "object") {
                          return asDict(r)[c.name]?.toString();
                        }
                        return asDict(r)[c.name].toString();
                      } catch (error) {
                        return `Render error : ${JSON.stringify(
                          asDict(r)[c.name]
                        )}`;
                      }
                    })();

                    return (
                      <TableCell
                        style={{
                          ...c.cellStyle,
                          ...(c.sticky && {
                            zIndex: 1000,
                            backgroundColor:
                              (props.getRowStyle &&
                                props.getRowStyle(r)?.backgroundColor) ||
                              "white",
                            position: "sticky",
                            left: 0,
                          }),
                        }}
                        key={c.name}
                      >
                        {c.renderTooltip
                          ? c.renderTooltip(r, c, content)
                          : content}
                        {c.sticky && (
                          <span
                            style={{
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              right: 0,
                              backgroundColor: leoBorderColor,
                              width: 1,
                            }}
                          ></span>
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Suspense>
      </Table>
      {props.showLoading && <LEOInfiniteScrollSpinner />}
      {!props.showLoading && props.noItemsLabel && props.data.length === 0 && (
        <div
          style={{
            textAlign: "center",
            padding: leoSpacing * 3,
            paddingTop: 0,
            fontWeight: 600,
            opacity: 0.35,
          }}
        >
          {props.noItemsLabel}
        </div>
      )}
      {props.columnsResizable && !props.noScrollHint && (
        <HorizontalScrollHint uiStateKey={props.uiStateKey} />
      )}
    </div>
  );
};

const getSelectionColumn = <Row extends { id: string }, Name, Group>({
  tableProps: {
    disableSelectAll,
    onSelectionChange,
    selected,
    rowSelectable,
    columnsResizable,
    stickyHeader,
  },
  sortedData,
}: {
  tableProps: LEOTableProps<Row, Name, Group>;
  sortedData: LEOTableProps<Row, Name, Group>["data"];
}): LEOTableColumn<Row, Name, Group> => {
  // add selection column
  const sharedProps: CheckboxProps = {
    style: {
      marginTop: leoSpacing * -1.35,
      marginBottom: leoSpacing * -1.35,
      marginRight: -leoSpacing,
    },
    size: "small",
  };

  return {
    name: "__selection" as any as Name,
    label: "",
    cellStyle: {
      width: 30,
    },
    renderHeader: () => (
      <TableCell
        key={"__checkbox"}
        style={{
          width: 30,
        }}
      >
        {columnsResizable && stickyHeader && (
          <div
            style={{
              position: "absolute",
              height: 1,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: leoBorderColor,
            }}
          />
        )}
        {!disableSelectAll && (
          <LEOCheckbox
            {...sharedProps}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onSelectionChange && selected) {
                onSelectionChange(
                  selected.length === sortedData.length
                    ? []
                    : sortedData.map((r) => (r as any as IRowKey).id)
                );
              }
            }}
            checked={(selected || []).length === sortedData.length}
          />
        )}
      </TableCell>
    ),
    render: (row) => {
      if (!rowSelectable || rowSelectable(row)) {
        return (
          <LEOCheckbox
            {...sharedProps}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (onSelectionChange && selected) {
                onSelectionChange(
                  selected.includes(row.id)
                    ? selected.filter((sId) => sId !== row.id)
                    : [row.id, ...selected]
                );
              }
            }}
            checked={(selected || []).includes(row.id)}
          />
        );
      }
    },
  };
};

interface HorizontalScrollHintProps {
  /** state key for saving local dismissed  */
  uiStateKey?: string;
}

const HorizontalScrollHint = (props: HorizontalScrollHintProps) => {
  /**
   * Local state
   */

  const ref = useRef<HTMLElement>();
  const parentRef = useRef<HTMLElement>();
  const [showing, setShowing] = useState(false);
  const [tempDismissed, setTempDismissed] = useState(false);
  const [dismissed, setDismissed] = useLocalStorage(
    props.uiStateKey + "-scroll-hint-dismissed",
    false
  );

  /**
   * Effects
   */

  const checkHint = useCallback(() => {
    if (parentRef.current) {
      const hasScroll =
        parentRef.current.clientWidth < parentRef.current.scrollWidth;
      if (hasScroll && !tempDismissed && !dismissed) {
        setShowing(true);
      } else {
        setShowing(false);
      }
    }
  }, [dismissed, tempDismissed]);

  useEventListener("resize", () => {
    checkHint();
  });

  useLayoutEffect(() => {
    _.delay(() => checkHint(), 2500);
  }, [checkHint]);

  useEffect(() => {
    parentRef.current = ref.current.parentElement;
    parentRef.current.addEventListener("scroll", () => {
      setTempDismissed(true);
      setShowing(false);
    });
  }, [ref]);

  return (
    <div
      ref={ref as any}
      style={{
        position: "fixed",
        right: 0,
        top: "75%",
        width: 1,
        height: 1,
      }}
    >
      {ref.current && showing && (
        <Popper anchorEl={ref.current} open>
          <Card
            style={{
              backgroundColor: leoColorPetrol5,
            }}
          >
            <CardContent>
              <Typography variant={"h3"}>{"Wide table"}</Typography>
              {"Use "}
              <strong>{"SHIFT"}</strong>
              {" + "}
              <strong>{"Scroll"}</strong>
              {" to navigate horizontally"}
              <div>
                <LEOSecondaryButton
                  style={{
                    marginTop: leoSpacing * 2,
                  }}
                  size={"small"}
                  onClick={() => {
                    setShowing(false);
                    setDismissed(true);
                  }}
                >
                  {"Ok, got it"}
                </LEOSecondaryButton>
              </div>
            </CardContent>
          </Card>
        </Popper>
      )}
    </div>
  );
};

export const LEOMemoedTable = React.memo<LEOTableProps<any, any, any>>(
  LEOTable,
  (prevProps, nextProps) => {
    // check hidden columns columns
    if (
      nextProps.hideColumns &&
      !_.isEqual(prevProps.hideColumns, nextProps.hideColumns)
    ) {
      return false;
    }

    // check showLoading prop
    if (!_.isEqual(prevProps.showLoading, nextProps.showLoading)) {
      return false;
    }

    //
    if (
      nextProps.columns.length &&
      !_.isEqual(prevProps.columns.length, nextProps.columns.length)
    ) {
      return false;
    }

    //

    if (
      nextProps.updateTrigger &&
      !_.isEqual(prevProps.updateTrigger, nextProps.updateTrigger)
    ) {
      return false;
    }

    // check selection change
    if (
      nextProps.selected &&
      !_.isEqual(prevProps.selected, nextProps.selected)
    ) {
      return false;
    }

    // check active
    if (!_.isEqual(prevProps.active, nextProps.active)) {
      return false;
    }

    // check columns order
    if (
      nextProps.columnsOrder &&
      !_.isEqual(prevProps.columnsOrder, nextProps.columnsOrder)
    ) {
      return false;
    }

    // check sort
    if (nextProps.sort !== prevProps.sort) {
      return false;
    }

    // check columns
    if (
      !_.isEqual(
        prevProps.columns.map((c) => c.name),
        nextProps.columns.map((c) => c.name)
      )
    ) {
      return false;
    }

    // check rows length
    if (prevProps.data.length !== nextProps.data.length) {
      return false;
    }

    // do deep comparison of individual rendered rows
    const activeColumns = nextProps.hideColumns
      ? nextProps.columns.filter((c) => !nextProps.hideColumns.includes(c.name))
      : nextProps.columns;
    const prev = filterRows(prevProps.data, activeColumns);
    const next = filterRows(nextProps.data, activeColumns);

    /** util for finding diffs
   prev.forEach((p, i) => {
     const n = next[i];
     if (n && p) {
       const pj = JSON.stringify(p, null, 2)
       const nj = JSON.stringify(n, null, 2)
       if (!_.isEqual(pj, nj)) {
         // console.log(pj, nj);
        }
      }
    })
 */

    const prevJSON = JSON.stringify(prev);
    const nextJSON = JSON.stringify(next);
    const isEqual = prevJSON === nextJSON;
    return isEqual;
  }
);

const filterRows = (
  rows: any[],
  columns: LEOTableColumn<any, any, any>[]
): any[] => {
  return rows.map((r) => {
    const cleanRow = {};
    columns.forEach((c) => {
      cleanRow[c.name] = c.render ? c.render(r, c) : r[c.name];
    });
    return cleanRow;
  });
};

// eslint-disable-next-line
function findDiff(str1, str2) {
  let diff = "";
  str2.split("").forEach(function (val, i) {
    if (val !== str1.charAt(i)) diff += val;
  });
  return diff;
}
