import { LEOTableColumn } from "LEOTheme/components/LEOTable";

export interface Epic {
  id: string;
  title: string;
  isASystem: boolean;
  isGxP: boolean;
  systemValidated: boolean;
  biaDone: boolean;
  itsraDone: boolean;
  systemImpact: string;
  supplier: string;
  group: string;
  systemOwner: User;
  dataOwner: User;
  processOwner: User;
  slug: string;
  application?: {
    status: [
      {
        name: string;
        description: string;
        group: string;
        value: string | boolean | number;
      }
    ];
    envs: Env[];
  };
}

export type EnvType = "dev" | "test" | "prod";

export interface Env {
  type: EnvType;
  label: string;
  long: string;
  link: string;
  statusUrl?: string;
  backendUrl?: string;
  frontendUrl?: string;
  logUrl?: string;
}

interface User {
  displayName: string;
  uniqueName: string;
  imageUrl: string;
}

export const fetchEpics = async (): Promise<Epic[]> => {
  const response = await fetch("/epics.json");
  const epics = (await response.json()) as Epic[];

  /**
   * Sort the epics by group-slug identifier
   */
  function compareFn(a: Epic, b: Epic) {
    const left = `${a.group?.toLowerCase()}-${a.slug}`;
    const right = `${b.group?.toLowerCase()}-${b.slug}`;
    return left > right ? 1 : right > left ? -1 : 0;
  }

  /**
   * Add slug to epics and sort the epics
   */
  return epics
    .map((e) => ({
      ...e,
      slug: e.title.toLowerCase().split(" ").join("-"),
    }))
    .sort(compareFn);
};

export const columsFromEpicStatus = (
  epicStatus: Epic["application"]["status"]
): LEOTableColumn<any, any, any>[] => {
  return epicStatus.map((s) => ({
    name: s.name,
    label: s.name,
    columnHelpText: s.description,
    render: (r) => {
      return r[s.name];
    },
  }));
};
