import {
  Button,
  Dialog,
  ListItem,
  Popover,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { leoSpacing, leoStyleFlexRowCenter } from "LEOTheme/LEOTheme";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import { useAuth } from "LEOTheme/utils/use-auth";
import React, { useRef, useState } from "react";
import { LEOAbout } from "./LEOAbout";
import { LEORevokeAppPermissionInformation } from "./LEORevokeAppPermissionInformation";
import { LEOUserDisplay } from "./LEOUserDisplay";

export interface LEOAccountAvatarProps {
  /** Props for app ident and about screen */
  appMetadata: AppMetadata;
}

/**
 * Displays account name and signout button
 *
 * @param props LEOAccountAvatarProps
 */
export const LEOAccountAvatar = (props: LEOAccountAvatarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { auth, signOut } = useAuth();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [revokeAppPermissionDialogOpen, setRevokeAppPermissionDialogOpen] =
    useState(false);

  const closeAbout = () => {
    setIsOpen(false);
  };

  const anchorEl = useRef(null);

  const fragmentAboutButton = () => {
    return (
      <Button
        style={{
          marginLeft: leoSpacing,
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
        }}
      >
        {"About"}
      </Button>
    );
  };

  /**
   * Render
   */
  return (
    <>
      <div
        onClick={() => setPopoverOpen(true)}
        ref={anchorEl}
        style={{
          ...leoStyleFlexRowCenter,
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <LEOUserDisplay
          ddAllow
          user={{
            firstName: auth?.me?.firstName,
            lastName: auth.me?.lastName,
            username: auth?.me?.username,
          }}
        />
        {isMobile || fragmentAboutButton()}
      </div>
      <Popover
        open={popoverOpen}
        onClose={() => setPopoverOpen(false)}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        disableScrollLock={true}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ListItem
            style={{ borderTop: "none" }}
            button
            onClick={() => setRevokeAppPermissionDialogOpen(true)}
          >
            {"Revoke app permission"}
          </ListItem>
          <ListItem button onClick={() => signOut()}>
            {"Log out"}
          </ListItem>
        </div>
      </Popover>
      {isMobile && fragmentAboutButton()}
      <Dialog
        fullScreen={isMobile}
        disableScrollLock
        hideBackdrop
        color={"white"}
        open={isOpen}
        onBackdropClick={() => closeAbout()}
      >
        <LEOAbout
          close={() => setIsOpen(false)}
          useCase={"about"}
          appMetadata={props.appMetadata}
        />
      </Dialog>
      <Dialog
        fullScreen={isMobile}
        disableScrollLock
        color={"white"}
        open={revokeAppPermissionDialogOpen}
        onBackdropClick={() => setRevokeAppPermissionDialogOpen(false)}
      >
        <LEORevokeAppPermissionInformation
          onClose={() => setRevokeAppPermissionDialogOpen(false)}
        />
      </Dialog>
    </>
  );
};
