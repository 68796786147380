import { Theme, useMediaQuery } from "@material-ui/core";
import { NavigationItem } from "LEOTheme/interfaces/navigation";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import React, { useEffect } from "react";
import { useToast } from "../utils/toast-utils";
import { useLocalStorage } from "../utils/use-local-storage";
import LEOAppBar, { LEOAppBarProps } from "./LEOAppBar";
import { LEOGlobalSpinner } from "./LEOGlobalSpinner";
import LEONavBar, { LEONavBarProps } from "./LEONavBar";
import {
  LEONavigationPadding,
  LEONavigationPaddingProps,
} from "./LEONavigationPadding";

export interface LEOAppFrameProps {
  /** App bar props */
  LEOAppBarProps?: LEOAppBarProps;

  /** Nav bar props */
  LEONavBarProps?: LEONavBarProps;

  /** Nav bar props */
  LEONavigationPaddingProps?: Partial<LEONavigationPaddingProps>;

  /** Navigation items to render in LEONavBar and LEOMobileNav */
  navigationItems?: NavigationItem[];

  /** Main content of applicatoin. Typically a React Router Switch */
  children: React.ReactNode;

  /** Props for app ident and about screen */
  appMetadata: AppMetadata;
}

/**
 * Main app frame that includes LEOAppBar, LEONavBar and LEONavigationPadding
 *
 * @param props LEOAppFrameProps
 */
export const LEOAppFrame = (props: LEOAppFrameProps) => {
  const toast = useToast();
  const [confirmed, setConfirmed] = useLocalStorage(
    "non-chromium-error-confirmed",
    false
  );

  const isMobile = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down("xs"),
    { noSsr: true }
  );

  /**
   * Utils & Effects
   */
  useEffect(
    () => {
      try {
        if (isMobile) return;
        var isChrome =
          /Chrome/.test(navigator?.userAgent) &&
          /Google Inc/.test(navigator?.vendor);
        if (!isChrome && !confirmed) {
          toast.showWarning({
            text:
              "The R&D Data & Analytics department mainly develops applications supported by Chrome or Chromium based browsers. " +
              "\n You are currently not using a supported browser, and we cannot guarantee the behaviour of the application.",
            confirmButtonText: "OK",
            onClose: () => setConfirmed(true),
          });
        }
      } catch (error) {}
    }, // eslint-disable-next-line
    [isMobile]
  );

  return (
    <>
      <LEOGlobalSpinner />
      <LEOAppBar
        appMetadata={props.appMetadata}
        navigationItems={props.navigationItems}
        {...props.LEOAppBarProps}
      />
      {!!props.navigationItems && !isMobile && (
        <>
          <LEONavBar
            navigationItems={props.navigationItems}
            {...props.LEONavBarProps}
          />
        </>
      )}
      <LEONavigationPadding
        navBarPadding={props.navigationItems?.length > 0}
        {...props.LEONavigationPaddingProps}
      >
        {props.children}
      </LEONavigationPadding>
    </>
  );
};
