import {
  IconButton,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import {
  leoBorderColor,
  leoColorLightGreen0,
  leoColorLightGreen2,
  leoColorWhite,
} from "LEOTheme/LEOColors";
import {
  leoLayoutAppBarHeight,
  leoShadowUIRaised,
  leoSpacing,
  leoStyleFlexRowCenter,
  useUtilityClasses,
} from "LEOTheme/LEOTheme";
import { logError } from "LEOTheme/utils/error-utils";
import { useEventListener } from "LEOTheme/utils/use-event-listener";
import { useLocalStorage } from "LEOTheme/utils/use-local-storage";
import _ from "lodash";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { LEOTableColumn, LEOTableSort } from "./LEOTable";
const stringifySort = (sort: LEOTableSort): string => {
  return `${sort.direction === "ASC" ? "-" : ""}${sort.column}`;
};

const useStyles = makeStyles(() => ({
  resizeHandle: {
    "&:hover": {
      backgroundColor: leoColorLightGreen2,
    },
  },
  resizeHandleDragging: {
    backgroundColor: leoColorLightGreen2,
  },
}));

export interface LEOTableDraggableHeadProps<Row, Name, Group> {
  /** Columns rezie enabled */
  columnsResizable?: boolean;

  /** Filtered Columns of tabel */
  filteredColumns: LEOTableColumn<Row, Name, Group>[];

  /** Filtered Columns of tabel */
  columns: LEOTableColumn<Row, Name, Group>[];

  /** Callback when column clicked for sort change */
  onSortChange?: (sort: string) => void;

  /** Columns order change callback */
  onColumnsOrderChange?: (order: Name[]) => void;

  /** Columns order */
  columnsOrder?: Name[];

  /** Table element  reference */
  tableRef: Element;

  /** Apply padding to first and last cell */
  padSideCells?: boolean;

  /** Sort */
  sort: LEOTableSort;

  /** Classes */
  classes: Record<"paddedSideCells" | "columnHeader", string>;

  /** sticky header */
  stickyHeader?: boolean;

  /** offset for sticky header */
  stickyHeaderOffset?: number;

  /** key prefix for local storage */
  uiStateKey?: string;

  /** render filter dropdown */
  renderHeaderFilter?: (c: LEOTableColumn<Row, Name, Group>) => JSX.Element;
}

interface LEOTableColumnSnappoint<Name> {
  x: number;
  columnName: Name;
}

interface IColumnWidth {
  width: number;
  name: string;
}

/**
 *
 *
 * @param props LEOTableDraggableHeadProps
 */
export const LEOTableDraggableHead = <Row, Name, Group>(
  props: LEOTableDraggableHeadProps<Row, any, any>
) => {
  const utilClasses = useUtilityClasses();
  const { uiStateKey = "" } = props;
  const classes = useStyles();

  // resize related
  const [columnWidths, setColumnWidths] = useLocalStorage<IColumnWidth[]>(
    uiStateKey + "-column-widths",
    []
  );
  const [resizeingName, setResizeingName] = useState<string>(null);
  const [startResizeX, setStartResizeX] = useState<number>(null);
  const [startResizeWidth, setStartResizeWidth] = useState<number>(null);

  // drag related
  const [dragColumn, setDragColumn] = useState<Name | null>(null);
  const [mouseOriginX, setMouseOriginX] = useState(0);
  const [dragOffsetX, setdragOffsetX] = useState(0);
  const [draggedHeaderClone, setDraggedHeaderClone] = useState<DOMRect>(null);
  const [snapPoints, setSnapPoints] = useState<LEOTableColumnSnappoint<Name>[]>(
    []
  );
  const [snapTarget, setSnapTarget] =
    useState<LEOTableColumnSnappoint<Name>>(null);

  /**
   * Sticky header
   */

  const [manualSticky, setManualSticky] = useState(false);
  const tableEL = useRef<HTMLElement>();
  const theadEL = useRef<HTMLElement>();

  useEventListener("scroll", () => {
    if (
      props.stickyHeader &&
      props.columnsResizable &&
      tableEL.current &&
      theadEL.current
    ) {
      //
      const offsetY =
        tableEL.current.getBoundingClientRect().y + window.pageYOffset;

      //
      const scrollY = window.pageYOffset;

      //
      if (scrollY > offsetY - leoLayoutAppBarHeight) {
        theadEL.current.style.transform = `translateY(${Math.floor(
          scrollY - (offsetY - leoLayoutAppBarHeight)
        )}px)`;

        setManualSticky(true);
      } else {
        setManualSticky(false);
        theadEL.current.style.transform = null;
      }
    }
  });

  const hideTimout = useRef<any>();
  useEventListener("wheel", (e) => {
    if (manualSticky && e.deltaY !== 0) {
      theadEL.current.style.opacity = "0";
      clearTimeout(hideTimout.current);
      hideTimout.current = setTimeout(() => {
        theadEL.current.style.opacity = "1";
      }, 250);
    }
  });

  useLayoutEffect(() => {
    tableEL.current = document.querySelector("table");
    theadEL.current = document.querySelector("thead");
  }, []);

  /**
   * Resize columns
   */

  useEventListener("mousemove", (e) => {
    if (resizeingName) {
      const width = _.clamp(
        startResizeWidth + (e.clientX - startResizeX),
        30,
        1000000
      );
      setColumnWidths(
        columnWidths.find((cw) => cw.name === resizeingName)
          ? columnWidths.map((cw) => ({
              ...cw,
              ...(cw.name === resizeingName
                ? {
                    width,
                  }
                : {}),
            }))
          : [
              ...columnWidths,
              {
                name: resizeingName,
                width,
              },
            ]
      );
    }
  });

  useEventListener("mouseup", () => {
    if (resizeingName) {
      setResizeingName(null);
    }
  });

  /**
   *  DRAG COLUMN HANDLER
   */

  useEventListener("mouseup", () => {
    endDrag();
  });

  useEventListener("mousemove", (e) => {
    if (draggedHeaderClone && dragColumn) {
      // update offset
      setdragOffsetX(e.clientX - mouseOriginX);

      // update snap point
      const currentPosX = (document.querySelector("#dragged-header") as any)
        .offsetLeft;

      let closest = snapPoints[0];
      let dist = 10000000;
      snapPoints.forEach((sp) => {
        const d = Math.abs(currentPosX - sp.x);
        if (d < dist) {
          dist = d;
          closest = sp;
        }
      });

      // set snap target
      setSnapTarget(closest);
    }
  });

  const endDrag = () => {
    // end drag
    setDraggedHeaderClone(null);
    setDragColumn(null);
    setSnapTarget(null);

    if (!snapTarget) return;

    // update column order
    const arr = props.columns.map((c) => c.name);
    const fromIndex = arr.indexOf(dragColumn);
    const toIndex = arr.indexOf(snapTarget.columnName);

    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, dragColumn);

    props.onColumnsOrderChange(arr);
  };

  const initDrag = () => {
    if (!dragColumn) return;
    try {
      setSnapPoints(
        props.filteredColumns.map((c) => {
          return {
            x: document
              .querySelector(`#column-header-${c.name}`)
              .getBoundingClientRect().left,
            columnName: c.name,
          };
        })
      );

      setDraggedHeaderClone(
        document
          .querySelector(`#column-header-${dragColumn}`)
          .getBoundingClientRect()
      );
    } catch (error) {
      logError(error);
    }
  };
  useEffect(
    () => {
      if (dragColumn) {
        _.delay(() => initDrag(), 50);
      }
    },
    // eslint-disable-next-line
    [dragColumn]
  );

  const draggedHeaderWrapper =
    dragColumn && draggedHeaderClone ? (
      <TableRow
        id={"dragged-header"}
        style={{
          cursor: "pointer",
          zIndex: 5000,
          position: "fixed",
          top: draggedHeaderClone.y + 8,
          left: draggedHeaderClone.x - 3 + dragOffsetX,
          width: draggedHeaderClone.width,
          height: draggedHeaderClone.height + 13,
          backgroundColor: `rgba(255, 255, 255, .75)`,
          boxShadow: leoShadowUIRaised,
        }}
      >
        <TableCell
          style={{
            ...props.columns.find((c) => dragColumn === c.name)?.headerStyle,
            display: "block",
            paddingLeft: leoSpacing,
            paddingRight: leoSpacing,
            boxSizing: "border-box",
            position: "relative",
            width: "100%",
            height: "100%",
            backgroundColor: leoColorWhite,
          }}
          className={
            "MuiTableCell-root MuiTableCell-head " + props.classes.columnHeader
          }
        >
          {props.columns.find((c) => dragColumn === c.name).label}
        </TableCell>
      </TableRow>
    ) : null;

  /**
   * Effects and utils
   */

  const sort = props.sort;
  const toggleSort = (c: LEOTableColumn<Row, Name, Group>, e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (!sort) {
      props.onSortChange(
        stringifySort({
          column: c.sortKey || (c.name as any as string),
          direction: "DESC",
        })
      );
    } else if (sort.column !== (c.sortKey || c.name)) {
      props.onSortChange(
        stringifySort({
          direction: "DESC",
          column: c.sortKey || (c.name as any as string),
        })
      );
    } else {
      props.onSortChange(
        stringifySort({
          ...sort,
          direction: sort.direction === "ASC" ? "DESC" : "ASC",
        })
      );
    }
  };

  const wrapInTooltip = (
    c: LEOTableColumn<Row, Name, Group>,
    children: React.ReactElement
  ) => {
    if (c.columnHelpText) {
      return (
        <Tooltip
          arrow
          placement={"top"}
          title={
            c.columnHelpText ? (
              <>
                <strong>{c.label}</strong>
                <br />
                {c.columnHelpText}
              </>
            ) : null
          }
        >
          {children}
        </Tooltip>
      );
    } else {
      return children;
    }
  };

  /**
   * Render
   */
  return (
    <>
      <TableHead
        style={{
          ...(props.columnsResizable &&
            props.stickyHeader && {
              backgroundColor: leoColorWhite,
              position: "relative",
              zIndex: 1001,
            }),
        }}
      >
        <TableRow
          className={props.padSideCells && props.classes.paddedSideCells}
        >
          {props.columns.map((c, index) =>
            c.renderHeader ? (
              c.renderHeader(c)
            ) : (
              <TableCell
                key={c.name}
                id={`column-header-${c.name}`}
                className={`${props.classes.columnHeader} ${utilClasses.noSelect}`}
                style={{
                  position: "relative",
                  zIndex: 1000,
                  whiteSpace: "nowrap",

                  ...c.headerStyle,
                  ...(props.columnsResizable
                    ? {
                        width:
                          columnWidths.find((cw) => cw.name === c.name)
                            ?.width || c.headerStyle?.width,
                      }
                    : {}),
                  ...(props.stickyHeader && !props.columnsResizable
                    ? {
                        top:
                          leoLayoutAppBarHeight +
                          (props.stickyHeaderOffset || 0) -
                          1,
                        position: "sticky",
                        zIndex: 1000,
                      }
                    : {}),
                  ...(c.sticky && {
                    zIndex: 1500,
                    backgroundColor: "white",
                    position: "sticky",
                    left: 0,
                  }),
                }}
              >
                {snapTarget?.columnName === c.name && (
                  <div
                    style={{
                      position: "absolute",
                      top: -14,
                      left: -14,
                    }}
                  >
                    <ArrowDropDownIcon
                      style={{ fontSize: 30, color: leoColorLightGreen0 }}
                    />
                  </div>
                )}
                {props.columnsResizable && props.stickyHeader && (
                  <div
                    style={{
                      position: "absolute",
                      height: 1,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: leoBorderColor,
                    }}
                  />
                )}

                <div
                  style={{
                    ...leoStyleFlexRowCenter,
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <span
                    style={{
                      flex: 1,
                      cursor:
                        (props.onSortChange && c.sortable) || c.sortBy
                          ? "pointer"
                          : undefined,
                    }}
                    onClick={
                      props.onSortChange && (c.sortable || c.sortBy)
                        ? (e) => {
                            toggleSort(c, e);
                          }
                        : undefined
                    }
                  >
                    {wrapInTooltip(
                      c,
                      <span
                        key={c.name as string}
                        style={{
                          textOverflow: "ellipsis",
                          position: "relative",
                          marginRight: leoSpacing * 1,
                          opacity:
                            (c.sortKey || c.name) === dragColumn &&
                            draggedHeaderClone
                              ? 0.5
                              : 1,
                        }}
                      >
                        {c.label}
                      </span>
                    )}
                  </span>
                  <span
                    style={{
                      ...leoStyleFlexRowCenter,
                    }}
                  >
                    {sort && sort.column === (c.sortKey || c.name) && (
                      <span
                        onClick={
                          props.onSortChange && (c.sortable || c.sortBy)
                            ? (e) => toggleSort(c, e)
                            : undefined
                        }
                        style={{
                          ...(props.onSortChange && {
                            cursor:
                              c.sortable || c.sortBy ? "pointer" : undefined,
                          }),
                          position: "relative",
                        }}
                      >
                        {sort.direction === "ASC" ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </span>
                    )}
                    {props.renderHeaderFilter && props.renderHeaderFilter(c)}
                    {props.onColumnsOrderChange && (
                      <span
                        className="leo-table-drag-handle"
                        style={{
                          position: "relative",
                          ...leoStyleFlexRowCenter,
                        }}
                      >
                        <IconButton
                          onMouseDown={(e) => {
                            setdragOffsetX(0);
                            setDragColumn(c.name);
                            setMouseOriginX(e.nativeEvent.clientX);
                          }}
                          size="small"
                        >
                          <DragIndicatorIcon
                            style={{
                              fontSize: 15,
                            }}
                          />
                        </IconButton>
                      </span>
                    )}
                  </span>
                  {c.renderHeaderIcon && c.renderHeaderIcon(c)}
                </div>
                {(index !== props.columns.length - 1 ||
                  props.columnsResizable) && (
                  <div
                    className={
                      props.columnsResizable &&
                      clsx(
                        classes.resizeHandle,
                        resizeingName === c.name && classes.resizeHandleDragging
                      )
                    }
                    onMouseDown={(e) => {
                      setStartResizeX(e.clientX);
                      setStartResizeWidth(
                        document
                          .getElementById(`column-header-${c.name}`)
                          .getBoundingClientRect().width - 8
                      );
                      setResizeingName(c.name);
                    }}
                    style={{
                      paddingLeft: 5,
                      paddingRight: 0,
                      right: 0,
                      zIndex: 1000,
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      marginTop: -1.5 * leoSpacing,
                      cursor: props.columnsResizable && "col-resize",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        height: "100%",
                        width: 1,
                        right: 0,
                        backgroundColor: leoBorderColor,
                      }}
                    />
                  </div>
                )}
              </TableCell>
            )
          )}
        </TableRow>
      </TableHead>
      {draggedHeaderWrapper && <TableHead>{draggedHeaderWrapper}</TableHead>}
    </>
  );
};
