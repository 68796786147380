import { Tooltip } from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import React, { PropsWithChildren } from "react";

interface LEOTooltipWrapperProps extends PropsWithChildren<any> {
  tooltip: React.ReactNode | string;
}

/**
 *
 *
 * @param props LEOTooltipWrapperProps
 */
export const LEOTooltipWrapper = (props: LEOTooltipWrapperProps) => {
  /**
   * Render
   */
  return props.tooltip ? (
    <Tooltip title={props.tooltip} placement="top" arrow>
      <div>
        {props.children}
        <InfoOutlinedIcon
          fontSize="small"
          style={{ position: "relative", top: 3, left: 3 }}
        />
      </div>
    </Tooltip>
  ) : (
    props.children
  );
};
