import { Tooltip } from "@material-ui/core";
import { leoThemeConfig } from "leo-theme-config";
import { aquireMicrosoftGraphToken_Silent } from "LEOTheme/utils/auth-utils";
import { getCookie, setCookie } from "LEOTheme/utils/cookie-util";
import {
  createBlobUrl,
  getMyUserProfileImage,
  getUserProfileImage,
} from "LEOTheme/utils/microsoft-graph-utils";
import { useAuth } from "LEOTheme/utils/use-auth";
import _ from "lodash";
import React, { CSSProperties, useEffect, useState } from "react";

export type ProfileImageSize = "micro" | "small" | "medium" | "large";

export interface LEOProfilePictureProps {
  tooltip?: string | React.ReactNode;
  username: string;
  size: ProfileImageSize;
  style?: CSSProperties;
}

const COOKIE_KEY = "usersWithoutProfilePictures";

/**
 * Rounded profile picture
 *
 * @param props LEOProfilePictureProps
 */
export const LEOProfilePicture = (props: LEOProfilePictureProps) => {
  /**
   * Local state
   */

  const [profileImgSrc, setProfileImgSrc] = useState("");
  const { auth } = useAuth();

  // ket saved list of users without profile pictures to prevent reloading
  const skipUsers = JSON.parse(getCookie(COOKIE_KEY) || "[]") || [];

  /**
   * Effects and utils
   */
  const fetchProfileImage = async () => {
    if (leoThemeConfig.disableProfilePictures) return;

    // skip users that has no profile image
    if (skipUsers.includes(props.username)) {
      setProfileImgSrc(fallbackProfileImgSrc);
      return;
    }

    // test mail if valid leo mail or dummy
    if (!props.username?.includes("@leo-pharma.com")) {
      setProfileImgSrc(fallbackProfileImgSrc);
      return;
    }

    //
    const accessToken = await aquireMicrosoftGraphToken_Silent()
      .then((authResult) => authResult.accessToken)
      .catch(async (error) => {
        // We went through Microsoft Graph Auth Flow in LEOAuthProvider.
        console.error(
          "LEOProfilePicture.tsx: Failed to aquireMicrosoftGraphToken_Silent. Probably because user did not accept permissions. Error:",
          error
        );
        return null;
      });
    if (!accessToken) {
      return;
    }
    const blob =
      auth.account.username === props.username
        ? await getMyUserProfileImage(accessToken)
        : await getUserProfileImage(accessToken, props.username);
    if (blob) {
      const blobUrl = createBlobUrl(blob);
      setProfileImgSrc(blobUrl);
    } else {
      // save user whithout profile picture in cookie to prevent reload next time
      setCookie(
        COOKIE_KEY,
        JSON.stringify(_.uniq([...skipUsers, props.username])),
        3 // expire in 3 days
      );

      // fallback
      setProfileImgSrc(fallbackProfileImgSrc);
    }
  };

  const getImageWidthAndHeight = () => {
    switch (props.size) {
      case "micro":
        return 19;
      case "small":
        return 22;
      case "medium":
        return 32;
      case "large":
        return 42;
      default:
        return 22;
    }
  };

  useEffect(
    () => {
      fetchProfileImage();
    },
    // eslint-disable-next-line
    []
  );

  /**
   * Disabled render
   */

  if (leoThemeConfig.disableProfilePictures) {
    return null;
  }

  /**
   * utils
   */

  const imageEl = (
    <img
      src={profileImgSrc}
      style={{
        flexShrink: 0,
        width: getImageWidthAndHeight(),
        height: getImageWidthAndHeight(),
        borderRadius: "50%",
        ...props.style,
      }}
      alt={"profile_image"}
    ></img>
  );

  /**
   * Render
   */
  return (
    <>
      {profileImgSrc && (
        <>
          {props.tooltip ? (
            <Tooltip title={props.tooltip} arrow>
              {imageEl}
            </Tooltip>
          ) : (
            imageEl
          )}
        </>
      )}
      {!profileImgSrc && (
        <span
          style={{
            ...props.style,
            flexShrink: 0,
            display: "inline-block",
            width: getImageWidthAndHeight(),
            height: getImageWidthAndHeight(),
          }}
        />
      )}
    </>
  );
};

const getHostname =
  window.location.hostname === "127.0.0.1"
    ? window.location.hostname + ":" + window.location.port
    : window.location.hostname;

const fallbackProfileImgSrc =
  "https://" + getHostname + "/android-chrome-512x512.png";
