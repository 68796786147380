import { Card, makeStyles } from "@material-ui/core";
import {
  leoLayoutAppBarHeight,
  leoSpacing,
  leoStyleFlexRowCenter,
} from "LEOTheme/LEOTheme";
import { useLEOGlobalState } from "LEOTheme/utils/leo-global-state";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { LEOFeedbackSpinner } from "./LEOFeedbackSpinner";

interface LEOGlobalSpinnerProps {}

const useStyle = makeStyles(() => ({
  card: {
    transition: "all .2s ease-in-out",
  },
}));

/**
 * Display of global spinner
 *
 * @param props LEOGlobalSpinnerProps
 */
export const LEOGlobalSpinner = (props: LEOGlobalSpinnerProps) => {
  const [leoGlobalState] = useLEOGlobalState();
  const classes = useStyle();

  /**
   * Local state
   */

  const [isShowing, setIsShowing] = useState(false);

  /**
   * Effects and utils
   */

  const noActive = leoGlobalState.globalSpinners.filter(
    (gs) => gs.active
  ).length;

  useEffect(
    () => {
      const showing = noActive > 0;
      if (showing) {
        setIsShowing(true);
      } else {
        _.delay(() => setIsShowing(false), 200);
      }
    },
    // eslint-disable-next-line
    [noActive]
  );

  // width of card
  const width = 100;

  /**
   * Render
   */
  return (
    <Card
      className={classes.card}
      style={{
        position: "fixed",
        top: leoLayoutAppBarHeight + leoSpacing * 2,
        right: !isShowing ? -width - leoSpacing : 12,
        zIndex: 5000,
        width,
        padding: leoSpacing * 1.5,
        ...leoStyleFlexRowCenter,
        alignItems: "center",
      }}
    >
      <LEOFeedbackSpinner
        style={{ width: 30 }}
        state={noActive > 0 ? "spinning" : "success"}
      />
      <small>{"Saving"}</small>
    </Card>
  );
};
