import { CheckboxProps, Checkbox } from "@material-ui/core";
import React from "react";

export interface LEOCheckboxProps extends CheckboxProps {}

/**
 * Wrapper for MUI Checkbox.
 * Enforces primary color
 *
 * @param props LEOCheckboxProps
 */
export const LEOCheckbox = (props: LEOCheckboxProps) => {
  return <Checkbox color="primary" {...props} />;
};
