import LEOSpinner, { LEOSpinnerProps } from "./LEOSpinner"
import { CSSProperties } from "@material-ui/core/styles/withStyles"
import React from "react"
import { leoStyleFlexRowCenter, leoSpacing } from "LEOTheme/LEOTheme"

interface LEOInfiniteScrollSpinnerProps {

  /** Style */
  style?: CSSProperties;

  /** Label after the spinner */
  label?: string;
  
  /** Props of the spinner component */
  LEOSpinnerProps?: LEOSpinnerProps
}

export const LEOInfiniteScrollSpinner = (props: LEOInfiniteScrollSpinnerProps) => {

  /**
   * Render
   */

  return <div style={{
    padding: leoSpacing * 4,
    ...leoStyleFlexRowCenter,
    justifyContent: 'center',
    ...props.style
  }}>
    <LEOSpinner {...props.LEOSpinnerProps} />
    <span
      style={{
        marginLeft: leoSpacing
      }}
    >
      {props.label}
    </span>
  </div>
}