import { DialogContent, Link } from "@material-ui/core";
import { leoSpacing } from "LEOTheme/LEOTheme";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import React from "react";
import LEODialog from "./LEODialog";
import LEOPrimaryButton from "./LEOPrimaryButton";

interface LEOSupportAndFeedbackDialogProps {
  onClose: () => void;

  appMetadata: AppMetadata;
}

/**
 *
 *
 * @param props LEOSupportAndFeedbackDialogProps
 */
export const LEOSupportAndFeedbackDialog = (
  props: LEOSupportAndFeedbackDialogProps
) => {
  /**
   * Render
   */
  return (
    <LEODialog
      footer={
        <LEOPrimaryButton onClick={() => props.onClose()}>
          {"Ok"}
        </LEOPrimaryButton>
      }
      title="Support & Feedback"
      open
      onClose={() => props.onClose()}
    >
      <DialogContent>
        <p>
          {
            "If you have questions, problems or feedbak of any kind we would love to hear from you. "
          }
          {`Please reach out to us in our dedicated support & feedback channel:`}
        </p>
        <p>
          <Link
            href={props.appMetadata.feedbackChannel.teamsChannelURL}
            target="_blank"
            rel="noreferrer"
          >
            <strong
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                alt="Teams logo"
                style={{
                  marginRight: leoSpacing * 2,
                }}
                width={"30px"}
                src="microsoft-teams.svg"
              />
              {`${props.appMetadata.appTitle} Support & Feddback channel`}
            </strong>
          </Link>
        </p>
      </DialogContent>
    </LEODialog>
  );
};
