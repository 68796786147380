import { Grid } from "@material-ui/core";
import { Epic } from "fetch-epics";
import LEOPaddedContainer from "LEOTheme/components/LEOPaddedContainer";
import LEOSectionDivider from "LEOTheme/components/LEOSectionDivider";
import _ from "lodash";
import React from "react";
import { ProjectCard } from "./ProjectCard";

interface ProjectOverviewProps {
  /** projects */
  epics: Epic[];

  /** hidden projects  */
  hiddenProjects: string[];
}

/**
 *
 *
 * @param props ProjectOverviewProps
 */
export const ProjectOverview = (props: ProjectOverviewProps) => {
  /**
   * Utils
   */

  const groups = _.uniq(props.epics.map((p) => p.group));

  /**
   * Render
   */
  return (
    <LEOPaddedContainer>
      {groups.map((g) => (
        <div key={g}>
          <LEOSectionDivider>{g}</LEOSectionDivider>
          <Grid container spacing={2}>
            {props.epics
              ?.filter((e) => e.group === g)
              ?.filter((e) => !props.hiddenProjects.includes(e.title))
              .map((p) => (
                <ProjectCard key={p.id} epic={p} />
              ))}
          </Grid>
        </div>
      ))}
    </LEOPaddedContainer>
  );
};
