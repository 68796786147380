import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import {
  leoColorPetrol0,
  leoColorPetrol4,
  leoColorSunflower0,
  leoColorSunflower4,
} from "LEOTheme/LEOColors";
import {
  leoLayoutAppBarHeight,
  leoShadowUIRaisedLarge,
  leoSpacing,
  leoStyleFlexRowCenter,
} from "LEOTheme/LEOTheme";
import { hexToRGBA } from "LEOTheme/utils/string-utils";
import React from "react";
import LEOSecondaryButton from "./LEOSecondaryButton";
import { MessageStatus } from "./LEOStatusCheckProvider";

export interface LEOStatusMessageProps {
  /* message status */
  ms: MessageStatus;

  /** hidden  */
  hidden: boolean;

  /** on hide */
  onHide: () => void;
}

/**
 *
 *
 * @param props LEOStatusMessageProps
 */
export const LEOStatusMessage = (props: LEOStatusMessageProps) => {
  /**
   * Local state
   */

  /**
   * Queries
   */

  /**
   * Mutations
   */

  /**
   * Effects and utils
   */

  /**
   * Render
   */
  if (!props.ms || props.ms.type === "no-message" || props.hidden) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: leoLayoutAppBarHeight,
        ...leoStyleFlexRowCenter,
        justifyContent: "center",
        left: 0,
        right: 0,
        padding: leoSpacing * 3,
        zIndex: 2000,
        boxShadow: leoShadowUIRaisedLarge,
        backgroundColor: hexToRGBA(
          props.ms.type === "warning" ? leoColorSunflower4 : leoColorPetrol4,
          0.85
        ),
        textAlign: "center",
      }}
    >
      {props.ms.type === "warning" ? (
        <WarningRoundedIcon
          style={{
            marginRight: leoSpacing,
            fontSize: 23,
            color: leoColorSunflower0,
          }}
        />
      ) : (
        <InfoRoundedIcon
          style={{
            marginRight: leoSpacing,
            fontSize: 23,
            color: leoColorPetrol0,
          }}
        />
      )}
      {props.ms.message}
      <LEOSecondaryButton
        onClick={() => props.onHide()}
        style={{
          marginLeft: leoSpacing * 2,
        }}
        size={"small"}
      >
        {"Hide"}
      </LEOSecondaryButton>
    </div>
  );
};
