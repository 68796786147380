import { leoColorLightGreen0 } from "LEOTheme/LEOColors";
import React from "react";
import LEOPaddedContainer from "./LEOPaddedContainer";
import LEOPrimaryButton from "./LEOPrimaryButton";

interface LEORevokeAppPermissionInformationProps {
  onClose: () => void;
}

/**
 * Information about how to revoke app permissions
 *
 * @param props LEORevokeAppPermissionInformationProps
 */
export const LEORevokeAppPermissionInformation = (
  props: LEORevokeAppPermissionInformationProps
) => {
  /**
   * Render
   */
  return (
    <LEOPaddedContainer>
      <h3>Revoking app permissions</h3>
      <p>
        By using this application, you have allowed the application to read your
        public LEO Pharma profile information (name, email and profile image).
        This allows the application to make you and other users easily
        identifiable within the application.
      </p>
      <p>
        If you wish to revoke the application permission to read these data, you
        can go to:
      </p>
      <a
        style={{ color: leoColorLightGreen0 }}
        href="https://portal.office.com/account/?ref=MeControl#"
      >
        https://portal.office.com/account/?ref=MeControl#
      </a>
      <p>
        Then click <b>Change app permissions</b>. From there, you can{" "}
        <b>Revoke</b> any application permission previously given.
      </p>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <LEOPrimaryButton onClick={() => props.onClose()}>
          Close
        </LEOPrimaryButton>
      </div>
    </LEOPaddedContainer>
  );
};
