import { aquireMicrosoftGraphToken_Silent } from "./auth-utils";

/* Helpers */
const baseUrl = "https://graph.microsoft.com/v1.0";

export const createBlobUrl = (blob: any) => {
  const url = window.URL || window.webkitURL;
  const blobUrl = url.createObjectURL(blob);
  return blobUrl;
};

export const imageCache: { [email: string]: Blob } = {};

/* Graph data fetchers methods */

/**
 * Returns this user's profile information
 */
export const getUserDetails: (accessToken: string) => Promise<ADUser> = async (
  accessToken: string
) => {
  const result = await fetch(`${baseUrl}/me/`, {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  }).then((result) => result.json());
  return result;
};

/**
 * Returns src to this user's profile picture.
 */
export const getMyUserProfileImage = async (accessToken: string) => {
  if (imageCache["me"]) {
    return imageCache["me"];
  }
  const blob = await fetch(`${baseUrl}/me/photo/$value`, {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(async (result) => {
      if (result.ok) {
        imageCache["me"] = await result.blob();
        return imageCache["me"];
      } else return null;
    })
    .catch(() => null);
  return blob;
};

/**
 * Returns src to given user's profile picture.
 */
export const getUserProfileImage = async (
  accessToken: string,
  email: string
) => {
  if (imageCache[email]) {
    return imageCache[email];
  }
  const blob = await fetch(`${baseUrl}/users/${email}/photo/$value`, {
    headers: {
      Authorization: "Bearer " + accessToken,
    },
  })
    .then(async (result) => {
      if (result.ok) {
        imageCache[email] = await result.blob();
        return imageCache[email];
      } else return null;
    })
    .catch(() => null);
  return blob;
};

export const getUserInformationFromLeoId = async (leoId: string) => {
  const graphToken = await aquireMicrosoftGraphToken_Silent();
  const response = await fetch(
    `https://graph.microsoft.com/v1.0/users?$filter=mail eq '${leoId}@leo-pharma.com'`,
    {
      headers: {
        Authorization: "Bearer " + graphToken.accessToken,
        ConsistencyLevel: "eventual",
      },
    }
  );
  if (response.status !== 200) {
    return null;
  }
  const json = await response.json();
  return json.value[0] as ADUser;
};

/* Return types */
export interface ADUser {
  "@odata.id"?: string;
  id: string;
  displayName: string;
  givenName: string;
  surname: string;
  userPrincipalName: string;
  jobTitle: string;
  mail: string;
  mobilePhone: string;
  businessPhones: string[];
  officeLocation: string;
  preferredLanguage: string;
}
