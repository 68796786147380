import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Epic } from "fetch-epics";
import LEOPaddedContainer from "LEOTheme/components/LEOPaddedContainer";
import LEOSectionDivider from "LEOTheme/components/LEOSectionDivider";
import { LEOTable } from "LEOTheme/components/LEOTable";
import { LEOTooltipWrapper } from "LEOTheme/components/LEOTooltipWrapper";
import { leoColorError0, leoColorLightGreen0 } from "LEOTheme/LEOColors";
import { leoSpacing, leoStyleFlexRowCenter } from "LEOTheme/LEOTheme";
import _ from "lodash";
import React from "react";

interface StatusOverviewProps {
  /** projects */
  epics: Epic[];
}

/**
 *
 *
 * @param props StatusOverviewProps
 */
export const StatusOverview = (props: StatusOverviewProps) => {
  const status = props.epics.find((e) => e.application)?.application.status;
  if (!status) return null;

  const groupKeys =
    _.uniq(
      props.epics
        .find((e) => e.application)
        ?.application.status.map((s) => s.group)
    ) || [];

  const groups = groupKeys.map((gk) => ({
    group: gk,
    fields: status.filter((s) => s.group === gk).map((s) => s.name),
  }));

  /**
   * Render
   */
  return (
    <LEOPaddedContainer
      style={{
        marginBottom: leoSpacing * 8,
        marginTop: leoSpacing * 4,
      }}
    >
      {groups.map((g) => (
        <div key={g.group}>
          <LEOSectionDivider
            style={{
              marginTop: leoSpacing * 8,
              marginBottom: -leoSpacing * 2,
            }}
          >
            {g.group}
          </LEOSectionDivider>
          <LEOTable
            columns={[
              {
                name: "Feature",
                label: "Feature",
                render: ({ id }) => {
                  const hint =
                    props.epics
                      .find((e) => e.application)
                      ?.application.status.find((s) => s.name === id)
                      .description || undefined;
                  return (
                    <div
                      style={{ ...leoStyleFlexRowCenter, alignItems: "center" }}
                    >
                      <strong style={{ whiteSpace: "nowrap" }}>{id}</strong>
                      {hint && (
                        <LEOTooltipWrapper tooltip={hint}></LEOTooltipWrapper>
                      )}
                    </div>
                  );
                },
              },
              ...props.epics
                .filter((p) => p.application)
                .map((p) => ({
                  name: p.title,
                  label: p.title,
                  render: ({ id }) => {
                    const status = p.application.status.find(
                      (s) => s.name === id
                    );
                    switch (typeof status.value) {
                      case "boolean":
                        return status.value ? (
                          <CheckCircleIcon
                            style={{ color: leoColorLightGreen0 }}
                          />
                        ) : (
                          <RemoveCircleIcon style={{ color: leoColorError0 }} />
                        );
                      default:
                        return status.value;
                    }
                  },
                })),
            ]}
            data={props.epics
              .find((e) => e.application)
              .application.status.filter((s) => s.group === g.group)
              .map((s) => ({ id: s.name }))}
          />
        </div>
      ))}
    </LEOPaddedContainer>
  );
};
