import { Chip, makeStyles, Tooltip } from "@material-ui/core";
import HistoryIcon from "@material-ui/icons/History";
import LanguageIcon from "@material-ui/icons/Language";
import LaptopIcon from "@material-ui/icons/Laptop";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import StorageIcon from "@material-ui/icons/Storage";
import { CSSProperties } from "@material-ui/styles";

import { Env } from "fetch-projects";
import {
  leoColorError0,
  leoColorError1,
  leoColorError2,
  leoColorLightGreen3,
  leoColorPeacock0,
  leoColorPeacock3,
  leoColorPeacock4,
  leoColorWhite,
} from "LEOTheme/LEOColors";
import { leoGetBorderString, leoSpacing } from "LEOTheme/LEOTheme";
import React, { useEffect, useState } from "react";

interface EnvStatusTagProps {
  /** Env */
  env: Env;

  /** style */
  style?: CSSProperties;

  /** size */
  size?: "small" | "medium";

  /** as dot */
  asDot?: boolean;
}

const useStyles = makeStyles(() => ({
  link: {
    transition: "color .1s ease-in-out",
    color: "inherit",
    "&:active": {
      color: "inherit",
    },
    "&:visited": {
      color: "inherit",
    },
    "&:hover": {
      color: leoColorLightGreen3 + "!important",
    },
  },
  linkDisabled: {
    "&:visited": {
      color: "inherit",
    },
    opacity: 0.25,
    pointerEvents: "none",
  },
  root: {
    "& .links": {
      transition: "opacity .1s ease-in-out",
      opacity: 0.25,
    },
    "&:hover .links": {
      opacity: 1,
    },
  },
  up: {
    border: leoGetBorderString(leoColorPeacock0),
    backgroundColor: leoColorPeacock3,
    borderColor: leoColorPeacock0,
    "&:hover": {
      backgroundColor: leoColorPeacock4,
    },
  },
  down: {
    color: leoColorWhite,
    backgroundColor: leoColorError1,
    border: leoGetBorderString(leoColorError0),
    borderColor: leoColorError0,
    "&:hover": {
      backgroundColor: leoColorError2,
    },
  },
  dot: {
    borderWidth: 1.25,
    boxShadow: `inset 0px 2px 2px rgba(0, 0, 0, 0.2)`,
  },
}));

/**
 * Status tag
 *
 * @param props EnvStatusTagProps
 */
export const EnvStatusTag = (props: EnvStatusTagProps) => {
  const classes = useStyles();

  /**
   * Local state
   */

  const [healthIsOK, setHealthIsOK] = useState(true);
  const tick = 1000 * 60;
  const noHealthCheck =
    !props.env.statusUrl ||
    props.env.statusUrl === "" ||
    window.location.href.includes("127.0.0.1");

  /**
   * Effect
   */

  const checkStatus = async () => {
    try {
      const response = fetch(props.env.statusUrl, {
        method: "GET",
      });
      if ((await response).status === 200) {
        setHealthIsOK(true);
        setTimeout(() => checkStatus(), tick);
      } else {
        setHealthIsOK(false);
        setTimeout(() => checkStatus(), tick);
      }
    } catch (error) {
      setHealthIsOK(false);
      setTimeout(() => checkStatus(), tick);
    }
  };

  useEffect(() => {
    if (!noHealthCheck) {
      checkStatus();
    }
    // eslint-disable-next-line
  }, []);

  /**
   * Render
   */

  // dot
  if (props.asDot) {
    return (
      <div
        className={
          classes.dot +
          (!noHealthCheck ? " " + (healthIsOK ? classes.up : classes.down) : "")
        }
        style={{
          ...props.style,
          width: 10,
          height: 10,
          borderRadius: 15,
        }}
      ></div>
    );
  }

  return (
    <Tooltip
      arrow
      title={
        <>
          {[
            { label: "Public", key: "link" },
            { label: "Backend", key: "backendUrl" },
            { label: "Frontend", key: "frontendUrl" },
            { label: "Logs", key: "logUrl" },
          ].map((s) => (
            <Tooltip key={s.key} placement={"top"} title={s.label} arrow>
              <a
                onClick={(e) => e.stopPropagation()}
                className={
                  props.env[s.key] ? classes.link : classes.linkDisabled
                }
                target={"_blank"}
                style={{
                  marginLeft: leoSpacing * 0.75,
                  marginRight: leoSpacing * 0.75,
                }}
                href={props.env[s.key]}
                rel="noreferrer"
              >
                {(() => {
                  switch (s.key) {
                    case "link":
                      return <LanguageIcon />;
                    case "backendUrl":
                      return <StorageIcon />;
                    case "frontendUrl":
                      return <LaptopIcon />;
                    case "gatewayUrl":
                      return <MeetingRoomIcon />;
                    case "logUrl":
                      return <HistoryIcon />;
                  }
                })()}
              </a>
            </Tooltip>
          ))}
        </>
      }
      placement="top"
      interactive
    >
      <a
        style={{
          textDecoration: "none",
        }}
        target={"_blank"}
        onClick={(e) => {
          e.stopPropagation();
        }}
        href={props.env.link}
        rel="noreferrer"
      >
        <Chip
          className={
            noHealthCheck ? undefined : healthIsOK ? classes.up : classes.down
          }
          size={props.size || "small"}
          label={props.env.label}
          style={{
            ...props.style,
            position: "relative",
            fontWeight: 900,
            textDecoration: 0,
          }}
          onClick={async () => {}}
        />
      </a>
    </Tooltip>
  );
};
