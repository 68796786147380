import { Theme, useMediaQuery } from "@material-ui/core";
import { leoLayoutAppBarHeight, leoLayoutNavBarWidth } from "LEOTheme/LEOTheme";
import React from "react";
import { CSSProperties } from "react";

export interface LEONavigationPaddingProps {
  /** Apply padding for top bar LEOAppBar */
  appBarPadding?: boolean;

  /** Apply padding for side LEONavBar */
  navBarPadding?: boolean;

  /** Child nodes */
  children: React.ReactNode;

  /** Style */
  style?: CSSProperties;
}

/**
 * Basic padding of content to account for fixed LEOAppBar, LEONavBar
 *
 * @param props LEONavigationPaddingProps
 */
export const LEONavigationPadding = (props: LEONavigationPaddingProps) => {
  const { appBarPadding = true, navBarPadding = true } = props;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  /**
   * Render
   */
  return (
    <div
      style={{
        flex: 1,
        width: "100%",
        display: "flex",
        paddingTop: appBarPadding ? leoLayoutAppBarHeight : 0,
        paddingLeft: navBarPadding && !isMobile ? leoLayoutNavBarWidth : 0,
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};
