import { Dialog, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { columsFromEpicStatus, Epic } from "fetch-epics";
import LEOFieldValue from "LEOTheme/components/LEOFieldValue";
import LEOPaddedContainer from "LEOTheme/components/LEOPaddedContainer";
import LEOSectionDivider from "LEOTheme/components/LEOSectionDivider";
import {
  leoColorError0,
  leoColorLightGreen0,
  leoColorTextBlack,
  leoColorWhite,
} from "LEOTheme/LEOColors";
import { leoSpacing, leoStyleFlexRowCenter } from "LEOTheme/LEOTheme";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import { EnvStatusTag } from "./EnvStatusTag";

interface ProjectDialogProps {
  /** epic */
  epic: Epic;
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogWindow: {
    width: "80%",
    position: "absolute",
    overflowY: "scroll",
    display: "flex",
    right: 0,
    color: leoColorTextBlack,
    backgroundColor: leoColorWhite,
    "& .LEOTabsNav": {
      color: leoColorTextBlack,
    },
  },
}));

/**
 * Dialog for displaying epic details
 *
 * @param props ProjectDialogProps
 */
export const ProjectDialog = (props: ProjectDialogProps) => {
  const classes = useStyles();
  const history = useHistory();
  const status = props.epic?.application?.status;

  const statusGroupKeys =
    (status && _.uniq(props.epic?.application.status.map((s) => s.group))) ||
    [];

  const statusGroups =
    status &&
    statusGroupKeys.map((gk) => ({
      group: gk,
      fields: status.filter((s) => s.group === gk).map((s) => s.name),
    }));

  /**
   * Render
   */
  return (
    <Dialog
      classes={{ paper: classes.dialogWindow }}
      fullScreen
      disableScrollLock
      BackdropProps={{
        style: {
          opacity: 0.3,
        },
      }}
      open
      onClose={() => history.push("/")}
    >
      <LEOPaddedContainer
        style={{
          padding: leoSpacing * 8,
          paddingTop: leoSpacing * 8,
          paddingBottom: leoSpacing * 16,
        }}
      >
        <div
          style={{
            ...leoStyleFlexRowCenter,
            marginBottom: leoSpacing * 6,
          }}
        >
          <div
            style={{
              width: 60,
              height: 60,
              flexShrink: 0,
              borderRadius: leoSpacing * 1.5,
              backgroundImage: `url(/app-icons/${props.epic.id}.png)`,
              backgroundSize: "cover",
              marginRight: leoSpacing * 3,
              backgroundPosition: "center",
            }}
          />
          <div>
            <Typography style={{ marginBottom: leoSpacing }} variant={"h1"}>
              {props.epic.title}
            </Typography>
            {props.epic.application &&
              props.epic.application.envs.map((e, i) => (
                <EnvStatusTag
                  size="small"
                  style={{
                    marginRight: leoSpacing,
                  }}
                  key={i}
                  env={e}
                />
              ))}
          </div>
        </div>
        <LEOSectionDivider>{"Key system info"}</LEOSectionDivider>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <LEOFieldValue label={"System id"}>{props.epic.id}</LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"System name"}>
              {props.epic.title}
            </LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"Is GxP"}>
              {props.epic.isGxP && (
                <CheckCircleIcon style={{ color: leoColorLightGreen0 }} />
              )}
            </LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"System validated"}>
              {props.epic.systemValidated && (
                <CheckCircleIcon style={{ color: leoColorLightGreen0 }} />
              )}
            </LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"BIA done"}>
              {props.epic.biaDone && (
                <CheckCircleIcon style={{ color: leoColorLightGreen0 }} />
              )}
            </LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"ITSRA done"}>
              {props.epic.itsraDone && (
                <CheckCircleIcon style={{ color: leoColorLightGreen0 }} />
              )}
            </LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"System owner"}>
              {props.epic.systemOwner}
            </LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"Process owner"}>
              {props.epic.processOwner}
            </LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"Data owner"}>
              {props.epic.dataOwner}
            </LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"System impact"}>
              {props.epic.systemImpact}
            </LEOFieldValue>
          </Grid>
          <Grid item xs={4}>
            <LEOFieldValue label={"Supplier"}>
              {props.epic.supplier}
            </LEOFieldValue>
          </Grid>
        </Grid>
        {props.epic.application && (
          <>
            <LEOSectionDivider>{"Application status"}</LEOSectionDivider>
            {statusGroups.map((sg) => (
              <div key={sg.group}>
                <LEOSectionDivider TypographyProps={{ variant: "h5" }}>
                  {sg.group}
                </LEOSectionDivider>
                <Grid container spacing={4}>
                  {columsFromEpicStatus(props.epic.application.status)
                    .filter((c) => sg.fields.includes(c.name))
                    .map((c) => (
                      <Grid item xs={3} key={c.name}>
                        <LEOFieldValue
                          helpText={c.columnHelpText || undefined}
                          label={c.label}
                        >
                          {(() => {
                            const status = props.epic.application.status.find(
                              (s) => s.name === c.name
                            );
                            switch (typeof status.value) {
                              case "boolean":
                                return status.value ? (
                                  <CheckCircleIcon
                                    style={{ color: leoColorLightGreen0 }}
                                  />
                                ) : (
                                  <RemoveCircleIcon
                                    style={{ color: leoColorError0 }}
                                  />
                                );
                              default:
                                return status.value;
                            }
                          })()}
                        </LEOFieldValue>
                      </Grid>
                    ))}
                </Grid>
              </div>
            ))}
          </>
        )}
      </LEOPaddedContainer>
    </Dialog>
  );
};
