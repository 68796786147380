import {
  AppBar,
  AppBarProps,
  Button,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { NavigationItem } from "LEOTheme/interfaces/navigation";
import { leoColorTextBlack } from "LEOTheme/LEOColors";
import {
  leoGetBorderString,
  leoLayoutAppBarHeight,
  leoSpacing,
  leoStyleFlexRowCenter,
} from "LEOTheme/LEOTheme";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import { useLocalStorage } from "LEOTheme/utils/use-local-storage";
import { useMessageStatus } from "LEOTheme/utils/use-status-message";
import React, { useState } from "react";
import { LEOAccountAvatar } from "./LEOAccountAvatar";
import { LEOAppIdent } from "./LEOAppIdent";
import { LEOMobileNav } from "./LEOMobileNav";
import { LEOStatusMessage } from "./LEOStatusMessage";
import { LEOSupportAndFeedbackDialog } from "./LEOSupportAndFeedbackDialog";

export interface LEOAppBarProps extends AppBarProps {
  /** Navigation items for mobile nav */
  navigationItems?: NavigationItem[];

  /** insert elements to the left of the account avatar  */
  leftOfAvatar?: React.ReactNode;

  /**
   * Children added to right side of bar.
   * Use for top level actions
   */
  children?: React.ReactNode;

  /** Props for app ident and about screen */
  appMetadata: AppMetadata;

  /** Workspace dropdown component */
  workspaceDropdown?: JSX.Element;
}

/**
 * This component contains the navigation bar at the top
 * of the application.
 *
 * @return {JSX.Element}
 */
const LEOAppBar = (props: LEOAppBarProps) => {
  const theme = useTheme();
  const messageStatus = useMessageStatus();

  const [msHidden, setMSHidden] = useLocalStorage(
    "ms-" + messageStatus?.message,
    false
  );

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  /**
   * Local state
   */

  const [showSupportAndFeedbackDialog, setShowSupportAndFeedbackDialog] =
    useState(false);

  /**
   * Render
   */
  return (
    <>
      <AppBar
        elevation={0}
        style={{
          backgroundColor: "white",
          position: "fixed",
          zIndex: theme.zIndex.drawer + 1,
          height: leoLayoutAppBarHeight,
          paddingLeft: leoSpacing * (isMobile ? 2 : 3),
          paddingRight: leoSpacing * (isMobile ? 2 : 3),
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
          boxShadow: "none",
          color: leoColorTextBlack,
          borderBottom: leoGetBorderString(),
          ...props.style,
        }}
      >
        <div style={{ ...leoStyleFlexRowCenter }}>
          <LEOAppIdent appTitle={props.appMetadata.appTitle} />
          {props.workspaceDropdown && props.workspaceDropdown}
        </div>
        <div
          style={{
            display: "flex",
            ...leoStyleFlexRowCenter,
          }}
        >
          {props.leftOfAvatar}
          {!isMobile && (
            <>
              <LEOAccountAvatar appMetadata={props.appMetadata} />
              {props.appMetadata.feedbackChannel && (
                <Button
                  style={{
                    marginLeft: leoSpacing,
                  }}
                  onClick={(e) => {
                    setShowSupportAndFeedbackDialog(true);
                  }}
                >
                  {"Support & Feedback"}
                </Button>
              )}
            </>
          )}
          {isMobile && (
            <LEOMobileNav
              appMetadata={props.appMetadata}
              navigationItems={props.navigationItems}
            />
          )}
          {props.children}
        </div>
      </AppBar>
      {
        <LEOStatusMessage
          onHide={() => setMSHidden(true)}
          ms={messageStatus}
          hidden={msHidden}
        />
      }
      {props.appMetadata.feedbackChannel && showSupportAndFeedbackDialog && (
        <LEOSupportAndFeedbackDialog
          appMetadata={props.appMetadata}
          onClose={() => setShowSupportAndFeedbackDialog(false)}
        />
      )}
    </>
  );
};

export default LEOAppBar;
