import { leoThemeConfig } from "leo-theme-config";
import { LEOAuthProvider } from "LEOTheme/components/LEOAuthProvider";
import { LEOThemeProvider } from "LEOTheme/components/LEOThemeProvider";
import { LEOToastProvider } from "LEOTheme/components/LEOToastProvider";
import { LEOGlobalStateProvider } from "LEOTheme/utils/leo-global-state";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Main } from "./Main";

/**
 * Root App and main providers
 */
export const App = () => {
  return (
    <Router>
      <LEOGlobalStateProvider>
        <LEOToastProvider>
          <LEOThemeProvider>
            <LEOAuthProvider
              feOnlyAuth
              appMetadata={leoThemeConfig.appMetaData}
            >
              <Main appMetadata={leoThemeConfig.appMetaData} />
            </LEOAuthProvider>
          </LEOThemeProvider>
        </LEOToastProvider>
      </LEOGlobalStateProvider>
    </Router>
  );
};
