import { useQuery } from "@apollo/client";
import { Link } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { User, UserVariables } from "LEOTheme/gql/types/User";
import { USER } from "LEOTheme/gql/USER";
import { leoSpacing, leoStyleFlexRowCenter } from "LEOTheme/LEOTheme";
import React from "react";
import { LEOProfilePicture, LEOProfilePictureProps } from "./LEOProfilePicture";

export interface DisplayUser {
  firstName: string;
  lastName: string;
  username: string;
}

export interface LEOUserDisplayProps {
  /** Proile image props */
  LEOProfilePictureProps?: Partial<LEOProfilePictureProps>;

  /** User */
  user: DisplayUser;

  /** Label style */
  labelStyle?: CSSProperties;

  /** style */
  style?: CSSProperties;

  // hide picture
  hidePicture?: boolean;

  // name prefix
  prefix?: string;

  // suffix
  suffix?: string;

  /** subLine */
  subLine?: React.ReactNode | string;

  /** email link */
  emailLinkOnName?: boolean;

  /** allow datadog to record name */
  ddAllow?: boolean;
}

/**
 * Display user profile image and name
 *
 * @param props LEOUserDisplayProps
 */
export const LEOUserDisplay = (props: LEOUserDisplayProps) => {
  /**
   * Utils
   */

  const name = (
    <>
      {`${props.prefix || ""}${props.user.firstName} ${props.user.lastName}${
        props.suffix || ""
      }`}
      {!props.user.lastName && !props.user.firstName && props.user.username}
    </>
  );

  /**
   * Render
   */
  return (
    <span
      style={{
        ...leoStyleFlexRowCenter,
        alignItems: "center",
        marginTop: leoSpacing * 0.5,
        marginBottom: leoSpacing * 0.5,
        ...props.style,
      }}
    >
      {!props.hidePicture && (
        <LEOProfilePicture
          size={"small"}
          tooltip={props.user.username}
          username={props.user.username}
          style={{
            position: "relative",
            //top: -0.1 * leoSpacing,
            marginRight: leoSpacing * 1,
            ...props.LEOProfilePictureProps?.style,
          }}
          {...props.LEOProfilePictureProps}
        />
      )}
      <span
        data-dd-privacy={props.ddAllow ? "allow" : "hidden"}
        style={{
          whiteSpace: "nowrap",
          ...props.labelStyle,
        }}
      >
        {props.emailLinkOnName ? (
          <Link href={`mailto:${props.user.username}`} target={"_blank"}>
            {name}
          </Link>
        ) : (
          name
        )}
        {props.subLine && <div>{props.subLine}</div>}
      </span>
    </span>
  );
};

/** wrapper for LEOUserDisplay that will query the user based on the supplied ID */

interface LEOUserDisplayQueriedProps extends Omit<LEOUserDisplayProps, "user"> {
  userId: string;
}

export const LEOUserDisplayQueried = (props: LEOUserDisplayQueriedProps) => {
  /**
   * queries
   */

  const userQuery = useQuery<User, UserVariables>(USER, {
    variables: {
      id: props.userId,
    },
  });

  /**
   * Render
   */

  if (!userQuery.data) return null;

  return <LEOUserDisplay {...props} user={userQuery.data.user} />;
};
