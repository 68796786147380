import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  TypographyProps,
} from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { leoSpacing, leoStyleFlexRowCenter } from "LEOTheme/LEOTheme";
import React from "react";

const useStyles = makeStyles(
  (theme: Theme) => {
    return createStyles({
      root: {
        marginTop: (props: LEOSectionDividerProps) =>
          props.noTopMargin ? 0 : theme.spacing(4),
        marginBottom: (props: LEOSectionDividerProps) =>
          props.noBottomMargin ? 0 : "1.5em",
        paddingBottom: ".5em",
        borderBottom: (props) =>
          props.noDividerLine ? 0 : "1px solid " + theme.palette.divider,
        borderBottomColor: theme.palette.divider,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      },
    });
  },
  {
    name: "LEOSectionDivider",
  }
);

export interface LEOSectionDividerProps {
  /** Props for Typography element */
  TypographyProps?: TypographyProps;

  /** Style */
  style?: CSSProperties;

  /** Callback on click */
  onClick?: () => void;

  /** No top margin */
  noTopMargin?: boolean;

  /** No bottom margin */
  noBottomMargin?: boolean;

  /** Skip divider line */
  noDividerLine?: boolean;

  /** Skip divider line */
  padSides?: boolean;

  /** Action buttons to align right */
  actions?: React.ReactElement | false;

  /** non wrapped items */
  nonWrappedActions?: React.ReactElement;

  /** Children */
  children?: React.ReactNode;
}

/**
 * Use to highlight section change wrapping a Typography element and support for action buttons to the right.
 *
 * @param props LEOSectionDividerProps
 */
const LEOSectionDivider = (props: LEOSectionDividerProps) => {
  const { root } = useStyles(props);

  return (
    <div
      style={{
        marginTop: leoSpacing * 3,
        ...leoStyleFlexRowCenter,
        alignItems: "center",
        justifyContent: "space-between",
        flex: 1,
        ...(props.padSides && {
          paddingLeft: leoSpacing * 4,
          paddingRight: leoSpacing * 4,
        }),
        ...props.style,
      }}
      onClick={props.onClick}
    >
      <Typography
        style={{
          fontWeight: 900,
          marginTop: 0,
          flex: 1,
        }}
        variant={"h3"}
        {...props.TypographyProps}
        className={root}
      >
        {props.children}
        {props.actions ? <div>{props.actions}</div> : null}
      </Typography>
      {props.nonWrappedActions}
    </div>
  );
};

export default LEOSectionDivider;
