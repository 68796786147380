import {
  createStyles,
  makeStyles,
  Tab,
  TabProps,
  Tabs,
  Theme,
} from "@material-ui/core";
import {
  leoColorSand2,
  leoColorTextBlack,
  leoColorWhite,
} from "LEOTheme/LEOColors";
import { leoLayoutContainerPadding } from "LEOTheme/LEOTheme";
import { hexToRGBA } from "LEOTheme/utils/string-utils";
import React, { CSSProperties } from "react";

export interface LEOTabsNavItem extends TabProps {
  /** React.Node to render when the tab is active */
  content?: React.ReactElement;

  /** Tab props for overrid */
  TabProp?: TabProps;

  /** render content */
  renderContent?: () => JSX.Element | JSX.Element[];
}

export interface LEOTabsNavProps {
  /** Selected index */
  selectedIndex: number;

  /** Callback when selection changed */
  onSelectedChange: (index: number) => void;

  /** Tabs in nav */
  tabs: LEOTabsNavItem[];

  /** Aria Label */
  ariaLabel?: string;

  /** Actions to place to the right buttons. (Use small buttons) */
  rightActions?: React.ReactNode;

  /** Style to add to container */
  style?: CSSProperties;

  /** Seconday tabs. Use when rendering TabsNav within another TabsNav */
  secondary?: boolean;

  /** Style to apply to the panel */
  panelStyle?: CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    rightAction: {
      paddingRight: leoLayoutContainerPadding,
      flex: 1,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      "& .MuiButton-root + .MuiButton-root": {
        marginLeft: theme.spacing(1),
      },
    },
  });
});

/**
 * A tab nav (Tabs + container) to easily render a tabbed navigation
 *
 * @param props ILEOTabsNavProps
 */
const LEOTabsNav = (props: LEOTabsNavProps) => {
  const {
    tabs,
    ariaLabel,
    rightActions,
    style,
    panelStyle,
    secondary,
    selectedIndex,
    onSelectedChange,
  } = props;

  /**
   * Styles
   */
  const { rightAction } = useStyles();

  /**
   * Render
   */
  return (
    <div
      className={"LEOTabsNav"}
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        minHeight: 0,
        ...style,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: leoColorTextBlack,
        }}
      >
        <Tabs
          value={selectedIndex}
          onChange={(e, index) => onSelectedChange(index)}
          aria-label={ariaLabel}
        >
          {tabs.map((t, index) => (
            <Tab
              onClick={(e) => {
                e.stopPropagation();
              }}
              style={{
                marginRight: 2,
                fontSize: 14,
                ...(secondary
                  ? {
                      fontSize: 13,
                      backgroundColor:
                        index === selectedIndex
                          ? leoColorSand2
                          : hexToRGBA(leoColorSand2, 0.0),
                    }
                  : {}),
              }}
              textColor={leoColorTextBlack}
              key={index}
              label={t.label}
              {...t.TabProp}
            />
          ))}
        </Tabs>
        {rightActions && <div className={rightAction}>{rightActions}</div>}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          backgroundColor: leoColorWhite,
          ...(secondary
            ? {
                backgroundColor: leoColorSand2,
              }
            : {}),
          ...(panelStyle ? panelStyle : {}),
        }}
        key={selectedIndex}
      >
        {tabs[selectedIndex]
          ? tabs[selectedIndex].renderContent
            ? tabs[selectedIndex].renderContent()
            : tabs[selectedIndex].content
          : "No tab selected..."}
      </div>
    </div>
  );
};

export default LEOTabsNav;
