import { LEOThemeConfig } from "LEOTheme/utils/type-utils";

export const leoThemeConfig: LEOThemeConfig = {
  disableProfilePictures: false,
  disableGQLFiles: [],
  disableThemeGQLFiles: [
    "DELETE_MODEL_PERMISSION",
    "ALL_USEROBJECTPERMISSION_SLIM",
    "ALL_USEROBJECTPERMISSION",
    "ASSIGN_MODEL_PERMISSION",
    "ROLE",
    "ROLES",
  ],
  appMetaData: {
    appTitle: "R&D Data Science & AI Engineering Dashboard",
    contactName: "Josef Kjærgaard",
    contactEmail: "jokje@leo-pharma.com",
    byline: "Developed by R&D Data Science & AI Engineering",
  },
};
