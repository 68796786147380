import LEOPaddedContainer from "LEOTheme/components/LEOPaddedContainer";
import React from "react";

interface AnalyticsProps {}
interface Dashboard {
  title: string;
  url: string;
}

const dashboards: Dashboard[] = [
  {
    title: "DSAIE Application Sessions & Errors",
    url: "https://p.datadoghq.eu/sb/21dcce52-739d-11ec-b32f-da7ad0900005-9002d0de5bdbc312b83428c2b3142a7a",
  },
  {
    title: "iSearch database sessions",
    url: "https://p.datadoghq.eu/sb/21dcce52-739d-11ec-b32f-da7ad0900005-54e36db9de4e5ab28e8891e004ed1be6",
  },
];

/**
 * List of datadog dashboards
 *
 * @param props AnalyticsProps
 */
export const Analytics = (props: AnalyticsProps) => {
  /**
   * Render
   */
  return (
    <div>
      {dashboards.map((d) => (
        <LEOPaddedContainer key={d.url}>
          <iframe
            title={d.title}
            src={d.url}
            style={{
              width: "100%",
              height: 600,
              border: 0,
            }}
          />
        </LEOPaddedContainer>
      ))}
    </div>
  );
};
